<template>
  <div id="app">
    <ZoomPage />
  </div>
</template>

<script>
import ZoomPage from "./components/ZoomPage.vue";
export default {
  name: "App",
  components: {
    ZoomPage
  }
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .img-zoom {
    position: absolute;
    top: 200rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 375rem;
    height: 240rem;
    img {
      max-width: 3000rem;
      image-rendering: -webkit-optimize-contrast;
    }
  }
}
</style>
