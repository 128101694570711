export const sheet5 = [
  {
    id: "川味六冷碟",
    dishName: "川味六冷碟",
    dishNameEn: "Sichuan-style Six Cold Dishes",
    cover: "/sheet5/1-0-1-0.png", // 透明菜品图片
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,
    restaurants: [
      {
        rName: "西御园乡村酒店",
        rNameEn: "Xiyuyuan Countryside Hotel",
        cover: "/sheet1/3-1-1.jpg",
        address: "成都市郫都区老成灌路郫县客运中心北2公里",
        addressEn:
          "North 2 kilometers from Pidu Passenger Transport Center, Chengdu-Dujiangyan Road, Pidu District, Chengdu",
        phone: "028—67517888",
        tel: "028—67517888",
        longLatitude: "103.88,30.82",
        longLatitudeId: "B001C7VM1M",
      },
    ],
  },
  {
    id: "长寿回锅肉",
    dishName: "长寿回锅肉",
    dishNameEn: "Twice-Cooked Pork",
    cover: "/sheet5/1-0-2-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "红星饭店",
        rNameEn: "Red Star Hotel",
        cover: "/sheet1/1-1-1.jpg",
        address: "成都市郫都区郫筒街道梨园村5组203号",
        addressEn: "No. 203, Group 5, Liyuan Village, Pitong Street, Pidu District, Chengdu",
        phone: "028-87884296",
        tel: "028-87884296",
        longLatitude: "103.86,30.83",
        longLatitudeId: "B001C804PK",
      },
    ],
  },
  {
    id: "水煮狮子头",
    dishName: "水煮狮子头",
    dishNameEn: "Boiled big Meatballs",
    cover: "/sheet5/1-0-4-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "铜壶苑鑫苑",
        rNameEn: "Tonghuyuan Xinyuan Fish Restaurant",
        address: "成都市郫都区友爱镇升平村7组209号",
        addressEn: "No. 209, Group 7, Shengping Village, Youai Town, Pidu District, Chengdu",
        phone: "028-87806333",
        tel: "028-87806333",
        longLatitude: "103.85,30.82",
        longLatitudeId: "B0FFF53EFV",
      },
    ],
  },
  {
    id: "郫县豆瓣鱼",
    dishName: "郫县豆瓣鱼",
    dishNameEn: "Douban-Flavored Fish",
    cover: "/sheet5/1-0-3-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "印象泰和园",
        rNameEn: "Yinxiang. Taihe Garden",
        cover: "/sheet1/11-1-1.jpg",
        address: "成都市郫都区友爱镇升平村266号",
        addressEn: "No. 266, Shengping Village, You'ai Town, Pidu District, Chengdu",
        phone: "028-87883777",
        tel: "028-87883777",
        longLatitude: "103.85,30.82",
        longLatitudeId: "B0HU1CBRJY",
      },
    ],
  },
  {
    id: "大蒜犀浦鲶",
    dishName: "大蒜犀浦鲶",
    dishNameEn: "Stir-Fried Xipu Catfish with Garlic",
    cover: "/sheet5/1-0-6-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      // {
      //   rName: "蜀滋源",
      //   rNameEn: "Sichuan flavor source restaurant",
      //   address: "成都市郫都区郫筒街道望丛中路100号附4-5-6",
      //   addressEn: "No. 4-5-6, 100 Wangcong Middle Road, Pidu Street, Pidu District, Chengdu",
      //   phone: "028-87929589",
      //   tel: "028-87929589",
      //   longLatitude: "103.90,30.79",
      //   longLatitudeId: "B001C7ZWPH",
      // },
    ],
  },
  {
    id: "开水素三鲜",
    dishName: "开水素三鲜",
    dishNameEn: "Boiled Vegetables",
    cover: "/sheet5/1-0-8-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "尚水休闲庄",
        rNameEn: "Shangshui Leisure village",
        address: "成都市郫都区三道堰镇八步桥村8社",
        addressEn: "No. 8, Babuqiao Village, Sandaoyan Town, Pidu District, Chengdu",
        phone: "028-87981888",
        tel: "028-87981888",
        longLatitude: "103.92,30.86",
        longLatitudeId: "B0FFGLNSXF",
      },
    ],
  },
  {
    id: "菠萝香酥鸭",
    dishName: "菠萝香酥鸭",
    dishNameEn: "Pineapple Crispy Duck",
    cover: "/sheet5/1-0-5-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "盛世蓉和酒楼",
        rNameEn: "Shengshi Ronghe Restaurant",
        address: "成都市郫都区望丛西路桃园三期35号楼205号",
        addressEn:
          "Room 205, Building 35, Phase III of Taoyuan ,West Wangcong Road, Pidu District, Chengdu",
        phone: "028-87800999",
        tel: "028-87800999",
        longLatitude: "103.88,30.82",
        longLatitudeId: "B001C7ZWT5",
      },
    ],
  },
  {
    id: "海味桂花坛",
    dishName: "海味桂花坛",
    dishNameEn: "Seafood and Osmanthus soup",
    cover: "/sheet5/1-0-7-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "杨鸡肉陌上人家喜宴荟休闲庄",
        rNameEn: "Yang‘s Chicken Moshangrenjia XiyanHui Leisure Village",
        cover: "/sheet1/12-1-1.jpg",
        address: "成都市郫都区友爱镇升平村7组207号（太清路）",
        addressEn:
          "No. 207, Group 7, Shengping Village, Youai Town (Taiqing Road), Pidu District, Chengdu",
        phone: "028-87928858",
        tel: "028-87928858",
        longLatitude: "103.85,30.82",
        longLatitudeId: "B001C80VFO",
      },
    ],
  },
  {
    id: "太极蚕豆羹",
    dishName: "太极蚕豆羹",
    dishNameEn: "Tai Chi Broad Bean Soup",
    cover: "/sheet5/1-0-9-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "天房阁",
        rNameEn: "Tianfangge Restaurant",
        address: "成都市郫都区犀浦镇围城南街588号",
        addressEn: "No. 588, South Weicheng Street, Xipu Town, Pidu District, Chengdu",
        phone: "028-87856777",
        tel: "028-87856777",
        longLatitude: "103.96,30.75",
        longLatitudeId: "B001C8OAZU",
      },
    ],
  },
  {
    id: "时鲜水果盘",
    dishName: "时鲜水果盘",
    dishNameEn: "Fresh Fruit Platter",
    cover: "/sheet5/1-0-10-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "徐记尚河渔庄",
        rNameEn: "Xu's Shanghe Fishing Village",
        address: "成都市郫都区三道堰镇马街社区蜀源大道299号",
        addressEn:
          "No. 299, Shuyuan Avenue, Majie Community, Sandaoyan Town, Pidu District, Chengdu",
        phone: "028-87910353",
        tel: "028-87910353",
        longLatitude: "103.92,30.89",
        longLatitudeId: "B0FFGSF5SE",
      },
      // {
      //   rName: "水景湾休闲庄",
      //   rNameEn: "Shuijingwan leisure resort",
      //   address: "成都市郫都区唐昌镇二环路南西南段234号",
      //   addressEn:
      //     "234 Southwestern section of the Second Ring Road, Tangchang Town, Pixian District, Chengdu City",
      //   phone: "18781923045",
      //   tel: "18781923045",
      //   longLatitude: "103.82,30.92",
      //   longLatitudeId: "B0G30OK9SX",
      // },
      {
        rName: "紫御轩食府",
        rNameEn: "Ziyuxuan Food Palace",
        address: "成都市郫都区中信大道3段789号",
        addressEn: "No.789, Section 3 of Zhongxin Avenue, Pidu District, Chengdu",
        phone: "028-87928368",
        tel: "028-87928368",
        longLatitude: "103.86,30.81",
        longLatitudeId: "B001C8RHHN",
      },
    ],
  },
  {
    id: "豆瓣抄手",
    dishName: "豆瓣抄手",
    dishNameEn: "Douban Wontons",
    cover: "/sheet5/1-0-11-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      // {
      //   rName: "锦源酒店",
      //   rNameEn: "Jinyuan hotel",
      //   cover: "/sheet0/1-0-1.jpg",
      //   address: "成都市郫都区沙石街阳光便利店南60米",
      //   addressEn:
      //     "South 60 meters from Yangguang Convenience Store, Shashi Street, Pidu District, Chengdu",
      //   phone: "028-87959666",
      //   tel: "028-87959666",
      //   longLatitude: "103.97,30.83",
      //   longLatitudeId: "B0JAM7IB81",
      // },
    ],
  },
  {
    id: "蓝莓红嘴芋",
    dishName: "蓝莓红嘴芋",
    dishNameEn: "Blueberry Taro",
    cover: "/sheet5/1-0-12-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "花田人家",
        rNameEn: "Huatian Renjia",
        cover: "/sheet0/1-0-1.jpg",
        address: "成都市郫都区唐昌镇三中对面",
        addressEn: "Opposite the No. 3 Middle School, Tangchang Town, Pidu District, Chengdu",
        phone: "028-87862691",
        tel: "028-87862691",
        longLatitude: "103.83,30.92",
        longLatitudeId: "B0FFGIYOEI",
      },
    ],
  },
  {
    id: "清炒时蔬",
    dishName: "清炒时蔬",
    dishNameEn: "Stir-Fried Vegetables",
    cover: "/sheet5/1-0-13-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      // {
      //   rName: "刘氏庄园",
      //   rNameEn: "Liu's manor",
      //   cover: "/sheet0/1-0-1.jpg",
      //   address: "成都市郫都区友爱镇农科村西源大道与迎宾路交叉口东140米",
      //   addressEn:
      //     "140 meters east of the intersection of Xiyuan Avenue and Yingbin Road, Nongke Village, You'ai Town, Pidu District, Chengdu",
      //   phone: "13982128530",
      //   tel: "13982128530",
      //   longLatitude: "103.79,30.82",
      //   longLatitudeId: "B0FFLLU7N8",
      // },
    ],
  },
  {
    id: "蚂蚁杂粮饭",
    dishName: "蚂蚁杂粮饭",
    dishNameEn: "Boiled Chinese arrowhead with rice",
    cover: "/sheet5/1-0-14-0.png",
    description:
      "郫县豆瓣宴是传承川菜文化、体现“郫县豆瓣”独特风味、突出郫都餐饮特色的风味筵席。由以郫都当地绿色食材为材料，以郫县豆瓣为核心调料，运用川菜烹调技艺烹制出多种风味而又突出豆瓣特色的各色菜式组合而成。厨师根据筵席性质、餐厅环境和宾客特点，设计出一系列菜品的艺术组合。在原料和风味选择上，冷菜、热菜、小吃主料和味型不重复；在烹饪方法上，传统烧炒技艺与精细调理结合；在上菜顺序上，注重味型搭配衔接。",
    descriptionEn:
      `The Pixian Douban Banquet is a flavor feast that inherits the culture of Sichuan cuisine, embodies the unique flavor of "Pixian Douban", and highlights the characteristics of Pidu cuisine. It consists of a variety of dishes that use locally sourced green ingredients from Pidu, with Pixian Douban as the core seasoning, and are prepared using Sichuan cuisine cooking techniques to create a combination of various dishes that feature the flavor of Douban. Chefs design an artistic combination of dishes based on the nature of the banquet, restaurant environment, and characteristics of guests. In terms of ingredient and flavor selection, there is no overlap of main ingredients and flavor profiles between cold dishes, hot dishes, and snacks. Traditional stir-fry techniques are combined with meticulous preparation, and the order in which dishes are served emphasizes the pairing and connection of flavor profiles.`,

    restaurants: [
      {
        rName: "竹里湾乡村酒店",
        rNameEn: "Zhuliwan Countryside Hotel",
        address: "成都市郫都区友爱镇顺春路623号",
        addressEn: "No. 623, Shunchun Road, Youai Town, Pidu District, Chengdu",
        phone: "028-67500786",
        tel: "028-67500786",
        longLatitude: "103.84,30.81",
        longLatitudeId: "B001C8U031",
      },
      // todo: 这个新加的店铺没有归属菜品，单纯想要推荐，放在蚂蚁杂粮饭菜品，不影响数据。
      {
        rName: "铜壶苑泰兴休闲庄",
        rNameEn: "Tonghuyuan Taixing leisure village",
        address: "",
        addressEn: "",
        phone: "",
        tel: "",
        longLatitude: "103.85,30.82",
        longLatitudeId: "B0FFFZWUBD",
      },
    ],
  },
];
