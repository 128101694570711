export const sheet3 = [
  {
    id: "到家张醪糟（犀浦店）",
    belong: "犀浦街道",
    belongEn: "Xipu Street",
    rName: "到家张醪糟（犀浦店）",
    rNameEn: "Daojia Zhang Laozao (Xipu branch)",
    cover: ["/sheet3/1-1-1.jpg"],
    description:
      "青城山到家张醪糟起源于湖北省麻城县张氏家族，店内醪糟粉子、初一臊子面等美食代代相传，配上苏麻糍粑、红油鸡片、风味腊排等蜀地各类特色美食小吃，每一餐香甜咸鲜、温胃暖心。",
    descriptionEn:
      `Qingcheng Mountain Daojia Zhang Laozao( Fermented Glutinous Rice) originated from the Zhang family in Macheng County, Hubei Province. The food in the store, such as Fermented Glutinous Rice Noodles, Chuyi Saozi (minced meat) noodles, has been passed down from generation to generation.It is accompanied by various Sichuan specialty snacks,  su ma ci ba (a type of glutinous rice cake), spicy chicken, and cured meat. Each meal is a harmonious blend of sweet, salty, and umami flavors, providing both comfort and satisfaction.`,
    dishs: [
      {
        dishName: "红糖醪糟粉子",
        dishNameEn: "Fermented Glutinous Rice Noodles with Brown Sugar",
        cover: "/sheet3/1-0-2.jpg", // 菜品封面图地址
        description:
          "技艺心传。到家张醪糟用翁坛鲜酿醪糟，配以水磨糯米粉锅前手作，烹煮出来的醪糟粉子甘甜纯糯，飘香四溢，早已成了当地远近闻名的特色小吃。小锅煮、滚水下、大火猛煮，略带酒香的醪糟融合了古法的香甜。糯米石磨成浆，脱水为粉，粉白似玉，软糯有嚼劲，回香甘甜，粘稠不腻。",
        descriptionEn:
          `Daojia Zhang Laozao, using fresh fermented Laozao from Wengtan and water-milled glutinous rice flour, the dish is cooked in a pot by hand, producing a sweet and pure fermented glutinous rice noodles with a fragrant aroma that has become famous both locally and afar. By using a small pot, boiling it in boiling water, and then cook over high heat,the Laozao is slightly alcoholic, and the ancient method of cooking produces a fragrant sweetness. The glutinous rice is ground into a slurry using a stone mill, dehydrated into powder, and the resulting white powder is as smooth as jade, chewy, fragrant, sweet, and sticky without being greasy.`,
      },
      {
        dishName: "初一臊子面",
        dishNameEn: "Chuyi Saozi (minced meat) noodles",
        cover: "/sheet3/1-0-1.jpg", // 菜品封面图地址
        description:
          "就地取材特色风味火腿，每逢大年初一与季节食材巧妙融合了面条劲道，得到色味俱佳的“初一臊子面”。臊子香脆适口，面条劲道爽滑。只此一碗，浓浓家味。一碗醪糟一碗面，食者无不感受到风味到家，温暖到家，心绪到家。",
        descriptionEn:
          `It is made from local specialty flavored ham and skillfully combined with seasonal ingredients on the first day of the Chinese New Year to create a delicious and colorful dish. The noodles are chewy and smooth, and the fragrance of the ham is crisp and refreshing. It exudes a strong sense of home cooking. A bowl of Laozao and a bowl of noodles, and every customer can feel the warmth and flavor of home.`,
      },
    ],
    address: "成都市郫都区华都路润扬双铁广场3栋1层1层沿街1号附5号",
    addressEn:
      "Attached No.1 of No.5, First Floor Along the Street, Building 3, Runyang Shuangtie Plaza, Huadu Road, Pidu District, Chengdu ",
    phone: "13438091626",
    tel: "13438091626",
    longLatitude: "103.98,30.76",
    longLatitudeId: "B0ID9ZK2M2",
    left: 1164,
    top: 675,
    width: 66,
    height: 42,
  },

  {
    id: "大牛合潮汕鲜火锅（犀浦店）",
    rName: "大牛合潮汕鲜火锅（犀浦店）",
    rNameEn: "Da Niu He Chao Shan fresh beef hot pot (Xipu branch)",
    cover: ["/sheet3/2-1-1.jpg"],
    description:
      "潮汕鲜牛肉涮烤主题餐厅成立于2017年，是成都四方和餐饮公司旗下的精品品牌餐饮店。店铺引入潮汕鲜牛肉加工工艺及潮汕美食文化，独创性的将潮汕鲜牛肉、川渝火锅、时尚烤肉有机融合并本土化。顾客既可享用潮汕美食，还能品尝红艳沸腾的四川火锅、烤肉等。",
    descriptionEn:
      "The ChaoShan fresh beef hot pot and grill themed restaurant was established in 2017 and is a boutique brand under the Chengdu Sifanghe catering company. The restaurant introduces the processing techniques of ChaoShan fresh beef and the culinary culture of ChaoShan, creatively integrating ChaoShan fresh beef, Sichuan-Chongqing hot pot, and trendy barbecue while localizing the flavors. Customers can not only enjoy ChaoShan cuisine but also taste the vibrant and spicy Sichuan hot pot, grilled meats, and more.",
    dishs: [
      {
        dishName: "特色鲜牛肉火锅",
        dishNameEn: "Fresh beef hot pot",
        cover: "/sheet3/2-0-1.jpg",
        description:
          "特色鲜牛肉火锅采用内蒙大草原的牛，简单鲜切，并配上原味牛骨老汤或养生番茄靓汤，品牛肉鲜嫩可口、肉香四溢，喝原汤鲜味沁脾。",
        descriptionEn:
          "Their specialty dish is the fresh beef hot pot, which features beef from the Inner Mongolian grasslands, sliced simply and served with either original beef bone broth or nourishing tomato soup. The beef is tender and flavorful, while the soup is refreshing and satisfying.。",
      },
    ],
    address: "成都市郫都区犀浦双铁路88号",
    addressEn: "No. 88 Shuang Tielu, Xipu, Pidu District, Chengdu.",
    phone: "028-61037333",
    tel: "028-61037333",
    longLatitude: "103.98,30.76",
    longLatitudeId: "B0FFHWH8VL",
    left: 1200,
    top: 717,
    width: 60,
    height: 60,
    belong: "犀浦街道",
    belongEn: "Xipu Street",
  },

  // {
  //   id: "树语烤肉",
  //   belong: "郫筒街道",
  //   belongEn: "St.Pitong",
  //   rName: "树语烤肉",
  //   rNameEn: "Shuyu BBQ",
  //   cover: ["/sheet3/3.3-1-1.jpg"],
  //   description:
  //     "“树语烤肉”充分利用乡村自然条件，打造出3000平米花园式烤肉场景，提供花园烤肉、团建活动、围炉煮茶、生态露营、休闲采摘、小型聚会、村民文艺表演和民俗活动等丰富多彩的服务项目。",
  //   descriptionEn:
  //     '"Shuyu BBQ" fully utilizes the natural environment of the countryside and creates a 3000-square-meter garden-style BBQ scene, providing a variety of services such as garden BBQ, team building activities, tea-making around the fire, eco-camping, leisure picking, small gatherings, village cultural performances, and folk activities.',
  //   dishs: [
  //     {
  //       dishName: "孜然牛肉",
  //       dishNameEn: "Cumin beef",
  //       cover: "/sheet3/3.3-0-1.jpg",
  //       description:
  //         "一道以牛肉为主要食材，以孜然、香葱、生姜、干辣椒、花椒为调料制作而成的美食，口味咸鲜麻辣。",
  //       descriptionEn:
  //         '"Cumin beef" is a delicious dish mainly made of beef and seasoned with cumin, scallion, ginger, dried chili, and Sichuan peppercorn, with a salty, fresh, and spicy taste.',
  //     },
  //   ],
  //   address: "成都市郫都区濂溪村2社49号",
  //   addressEn: "No. 49, 2nd Society, Lianxi Village, Pidu District, Chengdu.",
  //   phone: "13980421809",
  //   tel: "13980421809",
  //   longLatitude: "103.87,30.82",
  //   longLatitudeId: "B0IAX71OGM",
  //   left: 703,
  //   top: 399,
  //   width: 48,
  //   height: 48,
  // },

  {
    id: "鸡独叫",
    rName: "鸡独叫",
    rNameEn: "Jidujiao",
    cover: ["/sheet3/4.4-1-1.jpg"],
    description:
      "鸡独叫小吃店创立于2000年，主营鲜肉、韭菜饺子，味道鲜美、令人回味无穷，经营至今每日生意盈门、深受广大顾客欢迎。",
    descriptionEn:
      `"Jidujiao" snack shop was founded in 2000, specializing in fresh meat and chive dumplings that are delicious and memorable. The shop has been thriving ever since, with a constant stream of customers and a wide following.`,
    dishs: [
      {
        dishName: "红油水饺",
        dishNameEn: "Dumpling",
        cover: "/sheet3/4.4-0-1.jpg",
        description:
          "鲜肉、韭菜馅饺子。新鲜猪肉搅成肉末加韭菜切碎搅拌均匀，然后用饺子皮包起，煮好后加调料（花椒面，大蒜，红油，白糖，醋）。",
        descriptionEn:
          "The dumplings are made with fresh minced pork and chopped leeks, wrapped in dumpling skin, and boiled. After cooking, they are seasoned with spices such as Sichuan peppercorn, garlic, chili oil, sugar, and vinegar. ",
      },
    ],
    address: "成都市郫都区郫筒街道书南巷92号",
    addressEn: "No. 92, Shunan Lane, Pitong Street, Pidu District, Chengdu",
    phone: "13683441086",
    tel: "13683441086",
    longLatitude: "103.89,30.80",
    longLatitudeId: "B0FFIPSYHO",
    left: 723,
    top: 570,
    width: 42,
    height: 45,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  {
    id: "君诗烘焙",
    rName: "君诗烘焙",
    rNameEn: "Junshi Handmade Bakery",
    cover: ["/sheet3/5.5-1-1.jpg"],
    description:
      "君诗手工烘焙是一家以健康烘焙为理念的社区烘焙店。榴莲千层、豆乳盒子等各类烘焙点心让人垂涎欲滴，绿植环绕的环境、精心装扮的庭院让人耳目一新。君诗烘焙在郫都有口口相传的美誉，常有各国友人在店内享受闲暇时光。",
    descriptionEn:
      `Junshi handmade bakery is a community bakery with a focus on healthy baking. Their delectable baked goods such as durian mille-feuille and soy milk boxes are irresistible, and the environment surrounded by greenery and a meticulously decorated courtyard is refreshing. Junshi bakery has a well-known reputation in the Chengdu Pidu District, it is commonly seen that friends from various countries enjoy their leisure time in the store.`,
    dishs: [
      {
        dishName: "榴莲千层",
        dishNameEn: "Durian Mille-Feuille",
        cover: "/sheet3/5.5-0-1.jpg",
        description:
          "君诗明星产品，由松软的戚风蛋糕层、每日现烫的法式可丽饼皮、细腻浓郁的高品质榴莲果肉以及奶味香醇的铁塔奶油层层叠叠制作而成。美味到本身不吃榴莲的顾客，在朋友极力推荐下浅尝后，便成功入坑榴莲千层的美味世界。",
        descriptionEn:
          `It is Junshi's star product. It is made up of soft chiffon cake layers, freshly baked French crepes, delicate and rich high-quality durian pulp, and creamy and fragrant Tower cream. Even customers who don't typically like durian are won over by the deliciousness of the durian layer cake after being recommended by friends.`,
      },
    ],
    address: "成都市郫都区创智北环路123号",
    addressEn: "No. 123, Chuangzhi North Loop Road, Pidu District, Chengdu",
    phone: "028-87878147",
    tel: "028-87878147",
    longLatitude: "103.90,30.79",
    longLatitudeId: "B0FFHICPKK",
    left: 783,
    top: 606,
    width: 39,
    height: 45,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  // {
  //   id: "AThirdWay无尽藏",
  //   rName: "AThirdWay无尽藏",
  //   rNameEn: "AThirdWay endless ",
  //   cover: ["/sheet3/6.6-1-1.jpg"],
  //   description:
  //     "无尽藏是郫都区新晋网红咖啡餐吧，主打云贵菜，兼备茶咖功能，露天的绿植花园、静谧的就餐环境、美味的特色菜品，深受顾客喜爱。",
  //   descriptionEn:
  //     "A ThirdWay endless is a newly popular cafe and restaurant in Pidu , specializing in Yungui cuisine and also serving tea and coffee. The outdoor green garden, peaceful dining environment, and delicious signature dishes have won the hearts of customers.",
  //   dishs: [
  //     {
  //       dishName: "香茅烤罗非鱼",
  //       dishNameEn: "Lemongrass grilled tilapia",
  //       cover: "/sheet3/6.6-0-1.jpg",
  //       description:
  //         "云南滇南地区美味佳肴，在傣味菜肴中知名度极高，烤鱼皮黄肉酥、外焦里嫩、鲜嫩清甜、咸鲜微辣，且香草的味道浓郁芳香，非常适合夏天食用。",
  //       descriptionEn:
  //         "Lemongrass grilled tilapia, a popular and well-known delicacy in the Dian Nan region of Yunnan, known for its crispy skin, tender and succulent flesh, fresh and sweet taste, and slight spiciness, with a rich and aromatic flavor of herbs. It is a perfect dish to enjoy in summer. ",
  //     },
  //   ],
  //   address: "成都市郫都区科化一路足球场旁（法院南门向南30米）",
  //   addressEn:
  //     "next to the soccer field on Kehua Yi Road, Xindu District, Chengdu (30 meters south of the courthouse's south gate).",
  //   phone: "19982019871",
  //   tel: "19982019871",
  //   longLatitude: "103.90,30.80",
  //   longLatitudeId: "B0I0R5XBLE",
  //   left: 822,
  //   top: 567,
  //   width: 45,
  //   height: 48,
  //   belong: "郫筒街道",
  //   belongEn: "St.Pitong",
  // },

  {
    id: "胖哥面馆",
    rName: "胖哥面馆",
    rNameEn: "Pangge Noodles",
    cover: ["/sheet3/7.7-1-1.jpg"],
    description:
      "胖哥面馆创店于2000年，主营面食类产品。在20余年的经营中，一直坚持运用传统工艺，使用新鲜上等食材，秉承热情服务。麻辣醇正的红油和爽口的泡菜是其最大的特色，其中杂酱泡菜干拌面和红油抄手等菜品深受广大食客喜爱。",
    descriptionEn:
      `Pangge Noodles, established in 2000, specializes in noodle-based dishes. Over the past 20 years, it has adhered to traditional techniques, using fresh and high-quality ingredients, and providing passionate service. Its specialties are the spicy and authentic red oil and refreshing pickled vegetables, wherein,the mixed nodles with meat sauce and pickled vegetable as well as spicy wontons being popular favorites among customers. `,
    dishs: [
      {
        dishName: "杂酱泡菜干拌面",
        dishNameEn: "Mixed Nodles with Meat Sauce and Pickled Vegetable",
        cover: "/sheet3/7.7-0-1.jpg",
        description:
          "爽口的泡菜、麻辣醇正的红油与川味杂酱面碰撞出极致的味蕾体验，让老郫县人吃了又吃，20余年喜爱如初。",
        descriptionEn:
          `Collision of refreshing pickled vegetables, spicy and authentic red oil, and Sichuan-style noodles with meat sauce, creating an ultimate taste experience that has been loved by customers for more than 20 years. `,
      },
    ],
    address: "成都市郫都区景德一巷10号",
    addressEn: "No.10, First Jinde Lane,Pidu District,Chengdu",
    phone: "15528050108",
    tel: "15528050108",
    longLatitude: "103.89,30.81",
    longLatitudeId: "B001C8OCW2",
    left: 745,
    top: 492,
    width: 28,
    height: 23,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  {
    id: "老号沱江桥曾荞面",
    rName: "老号沱江桥曾荞面",
    rNameEn: "Tuojiangqiao Brand Noodles",
    cover: ["/sheet3/8.8-1-1.jpg"],
    description:
      "老号沱江桥曾荞面创立于1993年，最初位于沱江河旁，后因城市改造，搬迁到景德一巷至今，主营荞麦面和酥肉豆花。荞面对于“三高”患者和健身瘦身的朋友来说是很好的选择。",
    descriptionEn:
      `Founded in 1993, was originally located by the Tuojiang River. Due to urban redevelopment, it relocated to Jingde Lane and has been operating there since. The restaurant specializes in buckwheat noodles and tofu pudding with crispy pork. Buckwheat noodles are a great choice for people with "three highs" (i.e. high blood pressure, high blood sugar, and high blood cholesterol) and those who are exercising or trying to lose weight.`,
    dishs: [
      {
        dishName: "荞面",
        dishNameEn: "Buckwheat noodles",
        cover: "/sheet3/8.8-0-1.jpg",
        description:
          "一种以荞麦面粉加水和成面团压平后切制而成的细面条，条细筋韧、挑起来不断条、清香利口。",
        descriptionEn:
          `Buckwheat noodles are made by mixing buckwheat flour with water to form a dough, which is then flattened and cut into thin noodles that are firm and chewy, do not break easily, and have a fragrant and tasty flavor. `,
      },
    ],
    address: "成都市郫都区景德一巷25-27号",
    addressEn: "No. 25-27, First Jingde Lane, Pidu District, Chengdu ",
    phone: "13550108706",
    tel: "13550108706",
    longLatitude: "103.88,30.81",
    longLatitudeId: "B0FFG1UEXV",
    left: 744,
    top: 525,
    width: 30,
    height: 38,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  {
    id: "芙蓉蹄花",
    rName: "芙蓉蹄花",
    rNameEn: "Furong Pig's Trotter",
    cover: ["/sheet3/9-1-1.jpg"],
    description:
      "芙蓉蹄花创立于1988年，该店经历三次迁址，1988年-1999年由胡玉蓉（现老板黄莉母亲）在郫筒街道成灌东路经营，1998年该店迁至一环路东北段典式楼3栋楼下，2001年因典式房的拆迁，该店再次迁至城东巷150号经营至今。经营34年，深受广大消费者喜爱。",
    descriptionEn:
      `Founded in 1988 by Hu Yurong (now owned by Huang Li's mother), the restaurant has undergone three relocations. From 1988 to 1999, it was operated on East Chengguan Road in Pitu Street. In 1998, the restaurant was moved to Building 3, Dianshi Building, Northeast Section of First Ring Road. In 2001, due to the demolition of Dianshi Building, the restaurant was relocated to No. 150, Chengdong Lane, where it has been operating ever since. After 34 years of operation, it is deeply loved by consumers.`,
    dishs: [
      {
        dishName: "芙蓉蹄花",
        dishNameEn: "Furong Pig's Trotter",
        cover: "/sheet3/9-0-1.jpg",
        description:
          "芙蓉蹄花是郫都区的一道特色传统名吃，属于川菜系，该菜品是以猪蹄为主要原料配以其他调味品精心煲煮而成的菜品。芙蓉蹄花外观肌肤胜雪，形似飞花，滑嫩细致，柔嫩爽口，香而不腻，汤香四溢。辅以其特制的洗澡泡菜，开胃提神，口感脆生，上口去腻，更是老少适宜。",
        descriptionEn:
          `Furong Pig's Trotter is a Sichuan cuisine dish made with pig's feet as the main ingredient and other seasonings carefully stewed. The appearance of Furong Pig's Trotter is beautiful and delicate, and its texture is smooth and tender. It is fragrant but not greasy, and the soup is full of aroma. The special homemade pickled vegetables are a perfect match to the dish, which can stimulate the appetite and refresh the mind. The pickled vegetables have a crispy texture and are not greasy, making it suitable for people of all ages. `,
      },
    ],
    address: "成都市郫都区城东巷150号",
    addressEn: "No. 150, Chengdong Lane, Pidu District, Chengdu",
    phone: "13538879084",
    tel: "13538879084",
    longLatitude: "103.99,30.82",
    longLatitudeId: "B0FFFAB4RZ",
    left: 820,
    top: 512,
    width: 30,
    height: 25,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  {
    id: "三两",
    rName: "三两",
    rNameEn: "Sanliang Restaurant",
    cover: ["/sheet3/10.10-1-1.jpg"],
    description:
      "2022年大众点评必吃榜上榜餐厅，一家集咖啡、甜品、每日现烤面包、异国风味料理、调酒综合一体的餐吧，店内冬阴功汤、印度黄油鸡、意面、咸蛋黄薯条等明星产品深受消费者喜爱。",
    descriptionEn:
      `2022 Must-Eat List of Dianping, offering a comprehensive range of coffee, desserts, daily baked bread, exotic cuisine, and cocktails. Its star products, including Tom Yum Gong, Indian butter chicken, pasta, and salted egg yolk french fries, are deeply loved by consumers.`,
    dishs: [
      {
        dishName: "印度黄油鸡",
        dishNameEn: "Indian Butter Chicken",
        cover: "/sheet3/10.10-0-1.jpg",
        description: "各种香料、番茄、黄油熬制的酱料搭配唐肚里烤鸡，口感浓郁。",
        descriptionEn:
          `The Indian butter chicken is made with a sauce of various spices, tomatoes, and butter, combined with roasted chicken. It has a rich and flavorful taste.`,
      },
    ],
    address: "成都市郫都区郫筒街道和平街155号",
    addressEn: "No. 155, Heping Street, Pitong Street, Pidu District, Chengdu",
    phone: "18180507749",
    tel: "18180507749",
    longLatitude: "103.89,30.80",
    longLatitudeId: "B0FFIK7SWS",
    left: 851,
    top: 517,
    width: 44,
    height: 36,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  {
    id: "吴记醪糟肉",
    rName: "吴记醪糟肉",
    rNameEn: "Wu's Lao Zao Rou",
    cover: ["/sheet3/11.11-1-1.jpg"],
    description:
      "吴记醪糟肉建店30余年，研发的醪糟肉在肉类菜品中独树一帜。醪糟肉选料于川猪精细部位，配之以店主特酿“醪糟酒” 和多种天然香料，采用祖传工艺精心腌制而成。",
    descriptionEn:
      `Wu Ji Lao Zao Rou has been in business for over 30 years, specializing in the development of Lao Zao Rou (fermented glutinous rice pork) in meat dishes. Lao Zao Rou is made from fine parts of Sichuan pork, marinated with the proprietor's specially brewed "Lao Zao wine" and a variety of natural spices using traditional techniques. `,
    dishs: [
      {
        dishName: "醪糟肉",
        dishNameEn: "Lao Zao Rou",
        cover: "/sheet3/11.11-0-1.jpg",
        description:
          "店主严遵“闻其味、辨其色、保其质”的祖训，选用80公斤左右，皮薄肉嫩的肥猪后腿肉，并用十多种上乘药物香料渗入醪糟等多道工艺而制成。使其具有芳香浓郁、口感细腻、入口化渣、酱香浓烈、余香悠长的独特风格。",
        descriptionEn:
          `The proprietor strictly follows the ancestral precept of "smelling the aroma, identifying the color, and preserving the quality", selecting approximately 80 kg of tender pork leg with thin skin, and infusing it with more than ten superior medicinal spices and Lao Zao through multiple processes to create a unique style with a strong fragrance, delicate taste, tender texture, rich and intense sauce flavor, and long-lasting aftertaste. `,
      },
    ],
    address: "成都市郫都区望丛中路250号附近",
    addressEn: "Near No. 250, Middle Wangcong Road, Pidu District, Chengdu",
    phone: "15828578778",
    tel: "15828578778",
    longLatitude: "103.89,30.80",
    longLatitudeId: "B001C089Z4",
    left: 665,
    top: 538,
    width: 38,
    height: 57,
    belong: "郫筒街道",
    belongEn: "St.Pitong",
  },

  {
    id: "田悦火锅",
    belong: "安靖街道",
    belongEn: "AnJing Street",
    rName: "田悦火锅",
    rNameEn: "Tianyue Hotpot",
    cover: ["/sheet3/12.12-1-1.jpg"],
    description:
      "田悦火锅位于锦城绿道沿线，是一家融合农耕文化和公园城市特色的火锅店，同时满足就餐、喝茶、娱乐、亲子等需求。",
    descriptionEn:
      `Tianyue Hotpot is located along the JinCheng Greenway and is a hotpot restaurant that combines rural culture and the characteristics of a park city. It caters to dining, drinking tea, entertainment, and family needs.`,
    dishs: [
      {
        dishName: "海鲜大拼盘",
        dishNameEn: "Seafood Platter",
        cover: "/sheet3/12.12-0-1.jpg",
        description:
          "蚝肉饱满、色淡黄、口感滑腻，虾肉味道鲜美、回味万千，鲍鱼肉质很紧、韧、润、鲜美，海鲜大拼盘海鲜靓、鲜、香、嫩滑、可口。",
        descriptionEn:
          `Their Seafood Platter is a must-try, with plump and lightly-colored oyster meat that has a smooth and slippery texture, delicious and flavorful shrimp meat that leaves a long-lasting aftertaste, and abalone meat that is firm, chewy, moist, fresh, and tasty. The Seafood Platter is not only visually stunning, but also fresh, fragrant, tender, smooth, and delicious. `,
      },
    ],
    address: "成都市郫都区安靖街道沙湾村10组300号",
    addressEn: "300, Group 10, Shawan Village, Anjing Street, Pidu District, Chengdu",
    phone: "028-87865678",
    tel: "028-87865678",
    longLatitude: "104.03,30.79",
    longLatitudeId: "B0IUZR5Q1T",
    left: 1191,
    top: 535,
    width: 93,
    height: 74,
  },

  {
    id: "陈鸭子",
    rName: "陈鸭子",
    rNameEn: "Chen’s Duck",
    cover: ["/sheet3/13-1-1.jpg"],
    description:
      "一家藏在小巷子里的无菜单式中餐馆，厨师根据当天的食材决定当天菜品，充分保证食材的新鲜度。小店位置虽较为偏僻，但生意红火、座无虚席。特色酱香鸭鲜嫩多汁、肥瘦得当，各类菜品鲜香可口。",
    descriptionEn:
      `The non-menu Chinese restaurant hidden in an alleyway, where the chef decides the daily dishes based on the freshest available ingredients. Despite its secluded location, the restaurant is always bustling with customers and every table is occupied. Their specialty sauce-braised duck is juicy and tender, with the perfect balance of fat and lean meat, while other dishes are also delicious and fragrant.`,
    dishs: [
      {
        dishName: "卤鸭子",
        dishNameEn: "Braised Duck",
        cover: "/sheet3/13-0-1.jpg",
        description:
          "四川卤制品中的上品，色泽金黄，皮酥肉嫩，卤味纯正，咸淡适度，油而不腻，卤香四溢。",
        descriptionEn:
          `Their specialty dish is the braised duck, which is considered a top-grade Sichuan preserved food. The duck has a golden color with crispy skin and tender meat, and is marinated in a pure and authentic braising sauce that is neither too salty nor too bland. The dish is also oily but not greasy, and the fragrance of the sauce permeates the entire dish.`,
      },
    ],
    address: "成都市郫都区安靖街道阳光路1000号83栋",
    addressEn: " Building 83, No.1000, Yangguang Road, Anjing Street, Pidu District, Chengdu",
    phone: "18048595162",
    tel: "18048595162",
    longLatitude: "104.03,30.74",
    longLatitudeId: "B0FFG83CGT",
    left: 1338,
    top: 717,
    width: 63,
    height: 51,
    belong: "安靖街道",
    belongEn: "AnJing Street",
  },

  {
    id: "舒回锅",
    rName: "舒回锅",
    rNameEn: "Shu’s Twice-Cooked Meat Restaurant",
    cover: ["/sheet3/14-1-1.jpg"],
    description:
      "舒回锅是一家充分体现川菜特色的中餐馆，主打菜品是“舒式汗蒸回锅肉”，其烹饪技艺被评为成都市郫都区非物质文化遗产。店铺环境清雅、视野开阔，适合公司聚餐、团建、婚宴、酒席及家庭小聚。",
    descriptionEn:
      `It is a Chinese restaurant that fully embodies the characteristics of Sichuan cuisine, specializing in the dish "Shu-style Steamed Twice-ooked Pork". Its cooking skills have been recognized as intangible cultural heritage in the Pidu of Chengdu city. The restaurant has an elegant environment with an open view, suitable for company dinners, team building, wedding banquets, family gatherings and other occasions.`,
    dishs: [
      {
        dishName: "回锅肉",
        dishNameEn: "Twice-Cooked Pork",
        cover: "/sheet3/14-0-1.jpg",
        description:
          "猪肉或蒸或煮，处理至七、八成熟，晾凉切片，再和葱姜蒜等下油锅煸炒，或配上蒜苗，或配上莲白，佐以豆瓣酱和油盐酱醋等，五味齐全，下饭首选。",
        descriptionEn:
          `Their signature dish is the Twice-Cooked Pork, which involves cooking pork by either steaming or boiling it until it is 70-80% cooked. After it has cooled, the pork is sliced and then stir-fried in oil with ingredients such as scallions, ginger, and garlic. The dish can also be paired with garlic sprouts or lotus root, and is seasoned with a variety of sauces such as doubanjiang, soy sauce, vinegar, and salt. This dish is a perfect match for rice and is a must-try. `,
      },
    ],
    address: "成都市郫都区安靖街道阳光路1000号11栋",
    addressEn: "Building 11, 1000 Yangguang Road, Anjing Street, Pidu District, Chengdu",
    phone: "17187310347",
    tel: "17187310347",
    longLatitude: "104.03,30.75",
    longLatitudeId: "B0FFK6M1BZ",
    left: 1317,
    top: 666,
    width: 54,
    height: 51,
    belong: "安靖街道",
    belongEn: "AnJing Street",
  },

  {
    id: "滨河鱼府",
    belong: "三道堰镇",
    belongEn: "Sandaoyan Town",
    rName: "滨河鱼府",
    rNameEn: "Binhe Fish Restaurant",
    cover: ["/sheet3/15-1-1.jpg"],
    description:
      "滨河鱼府创立于2009年，始终坚持“鲜活点杀”，以还原鱼品鲜美嫩滑的口感。锅底以纯正清油再配上自制豆瓣炒制而成，麻不刺喉，辣不上火，鱼肉鲜嫩入味，回味无穷。",
    descriptionEn:
      `Binhe Fish Restaurant was founded in 2009 and has always adhered to the principle of "freshly slaughtered" to restore the fresh and tender taste of the fish. The soup base is made from pure and clear oil and homemade fermented bean paste, which is neither too numbing nor too spicy, and the fish meat is fresh and flavorful with an endless aftertaste.`,
    dishs: [
      {
        dishName: "鱼苗火锅",
        dishNameEn: "Juvenile Fish Hotpot",
        cover: "/sheet3/15-0-1.jpg",
        description:
          "石锅苗苗鱼采用新鲜的特色幼鱼为主要的食材，秘制烹饪，不破坏鱼体内的任何元素反而与石锅中的矿物质融合，增强人体内的吸收和转化能力。",
        descriptionEn:
          `Shiguo Miaomiao Fish Hotpot uses fresh and unique juvenile fish as the main ingredient and is cooked with a secret recipe that does not damage any elements within the fish. Instead, it integrates with the minerals in the stone pot, enhancing the body's absorption and conversion ability.`,
      },
    ],
    address: "成都市郫都区三道堰镇柏条河路二段107—117号",
    addressEn: "107-117 Baotiaohe Road, Section 2, Sandaoyan Town, Pidu District, Chengdu",
    phone: "15928675862",
    tel: "15928675862",
    longLatitude: "103.91,30.87",
    longLatitudeId: "B001C8VTU0",
    left: 914,
    top: 288,
    width: 63,
    height: 69,
  },

  {
    id: "戴大肉",
    rName: "戴大肉",
    rNameEn: "Daidarou Snacks",
    cover: ["/sheet3/16.16-1-1.jpg"],
    description:
      "戴大肉指独具特色的凉拌猪头肉。大肉由猪头肉片成，肉片又薄又大，用秘制辣椒面等调料凉拌而成，爽脆入味。一盘做了20年的大肉传承了两代人，味道经得起考验，一片片红油海椒里翻滚的大肉让人吃的合不拢嘴。",
    descriptionEn:
      `Daidarou Snacks, which refers to the unique cold dish of sliced pig head meat, is a specialty dish in Sichuan cuisine. The meat slices are thin and large, and are mixed with a secret blend of chili powder and other seasonings to create a refreshing and delicious taste. The recipe for this dish has been passed down for two generations over the past 20 years, and its flavor has stood the test of time. The slices of meat coated with the red oil and pepper make you can't stop eating.`,
    dishs: [
      {
        dishName: "戴大肉",
        dishNameEn: "Dai Da Rou",
        cover: "/sheet3/16.16-0-1.jpg",
        description:
          "戴大肉肉片又大又薄，麻辣香脆，色鲜味美。配以浓稠软糯的红豆汤和丝滑滋润的米汤菜，由美味升华到美妙。",
        descriptionEn:
          `Dai Da Rou is made with thin and large slices of pork, which are crispy, spicy, and aromatic. It is served with a thick and soft red bean soup and silky and nourishing rice soup with vegetable, elevating the delicious flavors to a wonderful level.`,
      },
    ],
    address: "成都市郫都区三道堰镇永定街85号",
    addressEn: " No.85, Yongding Street, Sandaoyan Town, Pidu District, Chengdu ",
    phone: "13438943498",
    tel: "13438943498",
    longLatitude: "103.92,30.87",
    longLatitudeId: "B0FFIAWRWR",
    left: 927,
    top: 360,
    width: 75,
    height: 69,
    belong: "三道堰镇",
    belongEn: "Sandaoyan Town",
  },

  {
    id: "周仔鹅",
    rName: "周仔鹅",
    rNameEn: "Zhouzie Goose",
    cover: ["/sheet3/17.17-1-1.jpg"],
    description:
      "周仔鹅创立于1996年，精选农家散养仔鹅，采用十多种名贵香料和纯天然植物配方科学搭配制作，27年来坚持每日现卤现卖，致力于制作健康美味的本色卤味。",
    descriptionEn:
      `Established in 1996, Zhouzie Goose selects free-range young geese from local farms and uses more than ten precious spices and natural plant ingredients to make a scientifically formulated dish. For the past 27 years, they have upheld the artisan spirit of selling freshly braised meat every day, and are committed to providing healthy and delicious braised food.`,
    dishs: [
      {
        dishName: "卤鹅",
        dishNameEn: "Braised Goose",
        cover: "/sheet3/17.17-0-1.jpg",
        description:
          "新鲜仔鹅洗净去内脏，经盐腌制后，放入独门秘制老卤水中卤制，起锅后在表皮上均匀涂抹上一层麦芽糖，最后放入高温油锅里炸。卤鹅成品通体红润，口感酥脆，甜而不腻，老少皆宜。",
        descriptionEn:
          `The braised goose is made by cleaning and gutting fresh young geese, salting them, and then braising them in a unique and secret braising liquid. After it is cooked, a layer of maltose is evenly applied to the skin before it is fried in a high-temperature oil pot. The finished braised goose has a red and glossy appearance, a crispy texture, and a sweet but not greasy taste that is suitable for both young and old.`,
      },
    ],
    address: "成都市郫都区三道堰镇桥北街5号",
    addressEn: "No.5, Qiaobei Street, Sandaoyan Town, Pidu District, Chengdu ",
    phone: "13688130021",
    tel: "13688130021",
    longLatitude: "103.92,30.87",
    longLatitudeId: "B0FFFTVF66",
    left: 978,
    top: 297,
    width: 54,
    height: 54,
    belong: "三道堰镇",
    belongEn: "Sandaoyan Town",
  },

  {
    id: "白果林柴火鸡餐馆",
    belong: "友爱镇",
    belongEn: "Youai Town",
    rName: "白果林柴火鸡餐馆",
    rNameEn: "Baiguolin Firewood Chicken Restaurant",
    cover: ["/sheet3/21-1-1.jpg"],
    description:
      "白果林柴火鸡，创立于2014年6月，是郫都区最早的柴火鸡餐饮店，深受广大消费者好评，曾获四川电视台吃八方最佳风味奖、第十八届成都国际美食节推荐菜品、四川省烹饪协会首推品牌等荣誉。",
    descriptionEn:
      `Baiguolin Firewood Chicken Restaurant, founded in June 2014, is the earliest firewood chicken restaurant in the Pidu District, which has been highly praised by consumers. It has won honors such as the Best Flavor Award of Sichuan TV's "Eating in All Directions", Recommended Dish of the 18th Chengdu International Food Festival, and the First Recommended Brand of Sichuan Culinary Association.`,
    dishs: [
      {
        dishName: "柴火鸡",
        dishNameEn: "Firewood Chicken",
        cover: "/sheet3/21-0-1.jpg",
        description:
          "柴火鸡，是一道色香味俱全的西南现代佳肴，制作方法吸取了川菜以及川味火锅的特点，属于川菜系鸡肴中的一种，以制作简易、贴近自然、味道香辣为特点。烹制时火候温和带旺，肉嫩味香，油而不腻。",
        descriptionEn:
          `Firewood Chicken is a delicious modern Southwest cuisine, with a combination of color, aroma, and taste. Its preparation method draws on the characteristics of Sichuan cuisine and Sichuan-style hot pot. It is a type of chicken dish in Sichuan cuisine, characterized by its simplicity, naturalness, and spicy flavor. When cooked, the heat is moderate and the meat is tender and flavorful without being greasy.`,
      },
    ],
    address: "成都市郫都区友爱镇金台村银杏路501号",
    addressEn: "501 Yinxing Road, Jintai Village, Youai Town, Pidu District, Chengdu",
    phone: "18215573862",
    tel: "18215573862",
    longLatitude: "103.84,30.81",
    longLatitudeId: "B0FFIHKJX1",
    left: 483,
    top: 636,
    width: 72,
    height: 54,
  },

  {
    id: "郫都区泉水桃园餐厅（麻小度·青花椒鱼）",
    belong: "安德街道",
    belongEn: "Ande Street",
    rName: "郫都区泉水桃园餐厅（麻小度·青花椒鱼）",
    rNameEn: "Quanshui Taoyuan Restaurant (Maxiaodu Green Pepper Fish)",
    cover: ["/sheet3/22-1-1.jpg"],
    description:
      "四川电视台推荐，成都必吃美食。创办于2017年11月，凭借一款市场上从未出现的产品，快速的获得当地居民和路经司机的认可和追捧。品牌产品以青花椒口味为特色，活鱼现点现杀，锅底现炒，麻辣鲜香，坚持“用好料做好鱼”。6年多以来，一直位居成都鱼火锅排行榜首位。打破传统鱼火锅壁垒，以独特的青花椒口味，引领行业新方向。",
    descriptionEn:
      `Recommended by Sichuan TV, a must-try cuisine in Chengdu. Established in November 2017, this brand quickly gained recognition and popularity among local residents and passing drivers with its unique product that had never been seen in the market before. The brand's signature dishes feature a Sichuan green pepper flavor, with fresh fish prepared upon ordering, and the soup base freshly stir-fried. It offers a spicy and flavorful experience, adhering to the principle of using high-quality ingredients to make excellent fish dishes. For over six years, it has consistently ranked first in Chengdu's fish hotpot rankings, breaking the barriers of traditional fish hotpot and leading the industry in a new direction.`,
    dishs: [
      {
        dishName: "青花椒鱼",
        dishNameEn: "Green Pepper Fish",
        cover: "/sheet3/22-0-1.jpg",
        description:
          "青花椒鱼味道麻辣、鲜香滑嫩，咬上一口，鱼肉的香气合着花椒的麻辣，能够瞬间令你的舌尖跳跃起来。",
        descriptionEn:
          `Green pepper fish is a numbingly spicy and fresh fish dish with a tender and smooth texture. The aroma of the fish meat, combined with the spiciness of the Sichuan peppercorns, can instantly make your taste buds dance with joy.`,
      },
      {
        dishName: "毛巾馒头",
        dishNameEn: "Towel-like Steamed Buns",
        cover: "/sheet3/22-0-2.jpg",
        description:
          "口感蓬松，带有一点点甜味，长长一条扯着吃贼带劲， 单吃入口清甜嚼劲十足，蘸鱼汤吃别有一番椒麻风味。",
        descriptionEn:
          `The texture is fluffy with a hint of sweetness. It is enjoyable to tear and eat it in one long piece, providing a satisfying chew. When eaten alone, it has a refreshing sweetness and great texture. When dipped in fish soup, it offers a unique peppery and numbing flavor.`,
      },
    ],
    address: "成都市郫都区安德街道安宁村1组3号",
    addressEn: "No. 3, Group 1, Anning Village, Ande Street, Pidu District, Chengdu ",
    phone: "1380255595",
    tel: "1380255595",
    longLatitude: "103.80,30.89",
    longLatitudeId: "B0FFJEQA0K",
    left: 214,
    top: 96,
    width: 71,
    height: 87,
  },

  {
    id: "临夏清真餐馆羊肉火锅",
    rName: "临夏清真餐馆羊肉火锅",
    rNameEn: "Linxia Halal Restaurant",
    cover: ["/sheet3/20-1-1.jpg"],
    description:
      "该店是一家以牛羊肉和北方面食为主的清真餐厅。羊肉选用临夏肥瘦相宜鲜嫩羔羊，不腥不膻、细嫩鲜美，牛肉品质优良、嚼劲十足，各类面食劲道爽滑。",
    descriptionEn:
      `The restaurant is a halal eatery that specializes in beef, lamb, and Northern-style noodles. The lamb is selected from Lingxia which tender and flavorful, without any gamey or strong taste, while the beef is of high quality with a satisfying chew. Their various types of noodles are firm and smooth.`,
    dishs: [
      {
        dishName: "手抓羊肉",
        dishNameEn: "Lamb",
        cover: "/sheet3/20-0-1.jpg",
        description:
          "肥瘦相间的大块羊肉和羊排，满足大口吃肉的快感，羊排软香鲜嫩，搭配醋汁或秘制干碟，味美不塞牙。",
        descriptionEn:
          `Large pieces of fat and lean lamb and lamb chops bring the pleasure of eating meat in large bites. The lamb chops are soft, fragrant, and tender, have a delicious taste without choking teeth paired with vinegar sauce or secret dry dishes.`,
      },
    ],
    address: "成都市郫都区安德天府商贸城A1幢1-6号",
    addressEn: "No. 1-6, Building A1, Ande Tianfu Commercial Plaza, Pidu District, Chengdu",
    phone: "13032881751",
    tel: "13032881751",
    longLatitude: "103.80,30.87",
    longLatitudeId: "B001C7SM5B",
    left: 215,
    top: 195,
    width: 115,
    height: 75,
    belong: "安德街道",
    belongEn: "Ande Street",
  },

  {
    id: "正宗青椒鸡",
    belong: "德源街道",
    belongEn: "Deyuan Street",
    rName: "正宗青椒鸡",
    rNameEn: "The Authentic Green pepper Chicken",
    cover: ["/sheet3/21.21-1-1.jpg"],
    description:
      "本店建于2007年，店主父亲自创了青椒鸡这道特色菜，经过多年的实践和创新，青椒鸡得到大众好评，民间流传“到德源没吃过鸡汤捞饭就不算来过德源”。",
    descriptionEn:
      `Established in 2007,The restaurant's signature dish is the Green Pepper Chicken, which was created by the owner's father. After years of practice and innovation, the Green Pepper Chicken has been well-received by the public, and it is said that "If you haven't tried the Chicken Soup with Rice at Deyuan, you haven't really been to Deyuan."`,
    dishs: [
      {
        dishName: "德源青椒鸡",
        dishNameEn: "Deyuan Green pepper chicken",
        cover: "/sheet3/21.21-0-1.jpg",
        description:
          "又辣又麻是德源青椒鸡的一大特色，青椒的香，鸡肉的鲜，再加上底料的味道，肉吃够了还可以添煮素菜，麻辣鲜香，口味丰富，连汤汁都是泡饭、吃面的佳品，绝不让慕名而来的食客失望。",
        descriptionEn:
          `The Deyuan Green pepper Chicken is characterized by its spiciness and numbness, as well as the fragrance of green peppers and the freshness of chicken meat. In addition to the taste of the broth, you can also add cooked vegetables to the dish, making it rich in flavor. Even the soup is a delicacy that goes well with rice and noodles, the customers who come from far and wide will be never disappointed.`,
      },
    ],
    address: "成都市郫都区德源南路一段与007乡道交叉口南100米平城小区底商",
    addressEn:
      "the bottom of the Pingcheng Community, 100 meters south of the intersection of Deyuan South Road, Section 1 and 007 Township Road, Pidu District, Chengdu",
    phone: "15928671544",
    tel: "15928671544",
    longLatitude: "103.85,30.77",
    longLatitudeId: "B001C8MFNF",
    left: 567,
    top: 768,
    width: 60,
    height: 54,
  },

  {
    id: "成都恒邦天府喜来登酒店·采悦轩中餐厅",
    rName: "成都恒邦天府喜来登酒店·采悦轩中餐厅",
    rNameEn: "Sheraton Chengdu Pidu · Caiyuexuan Chinese Restaurant",
    cover: ["/sheet3/22.22-1-1.jpg"],
    description:
      "餐厅面积1150㎡，餐位数240，以成都漆器皿为灵感装饰，运用新鲜食材打造拥有成都当地风味的现代中餐厅。主打川菜，辅以经典粤菜，优雅大堂及18个私享包间是亲朋家人聚餐或商务宴请的上选。其中8个包间拥有令人陶醉的菁蓉湖景观，视野开阔，可远眺绵延山峦。",
    descriptionEn:
      `With an area of 1150 square meters and seating for 240 guests, the restaurant is decorated with Chengdu lacquerware as inspiration and uses fresh ingredients to create modern local-flavored Chinese cuisine. The restaurant's signature dishes are Sichuan cuisine, supplemented by classic Cantonese cuisine. The elegant lobby and 18 private dining rooms are perfect for dining with family and friends or business banquets. Among them, eight private rooms have a breathtaking view of the Jingrong Lake and the distant mountains.`,
    dishs: [
      {
        dishName: "砂锅鲍汁花胶乳鸽",
        dishNameEn: "Braised Pigeon with Abalone Sauce and Flower Jelly in a Casserole",
        cover: "/sheet3/22.22-0-1.jpg",
        description:
          '砂锅鲍汁花胶乳鸽以官府菜"鸽吞燕”为灵感,秘制乳鸽里酿入高汤精炖的鲍鱼、海参和花胶,可谓“内有乾坤,包罗万象”, 此道菜亦可现场烹制,食材浓香四溢,尽显大厨深厚的烹饪功底。',
        descriptionEn:
          `Inspired by the imperial dish "Ge Tun Yan", this casserole is stuffed with pigeon that has been stewed with abalone, sea cucumber and flower jelly in a rich broth. The dish can also be cooked on-site, with fragrant ingredients that highlight the chef's profound cooking skills.`,
      },
    ],
    address: "成都市郫都区大禹西路恒邦天府喜来登酒店3楼",
    addressEn:
      "the 3rd floor of Sheraton Chengdu Pidu, West Dayu Road, Pidu District, Chengdu",
    phone: "028-68277777",
    tel: "028-68277777",
    longLatitude: "103.86,30.79",
    longLatitudeId: "B0IUMZNYZF",
    left: 576,
    top: 639,
    width: 81,
    height: 123,
    belong: "德源街道",
    belongEn: "Deyuan Street",
  },

  {
    id: "唐元沙西冒菜",
    belong: "唐昌镇",
    belongEn: "Tangchang Town",
    rName: "唐元沙西冒菜",
    rNameEn: "Tangyuan Shaxi Maocai",
    cover: ["/sheet3/23.23-1-1.jpg"],
    description:
      "沙西冒菜是一家千禧年老店，每日选用新鲜大棒骨加秘制香料花费6小时熬制的高汤做锅底，再配上纯手工制作的海椒面蘸料，味道独特。特色菜品牛肉、脑花、大耗儿鱼、牛筋、鸡爪、排骨等深受大众好评。",
    descriptionEn:
      `Tangyuan Shaxi Maocai is a millennium-old restaurant that uses fresh large bone and secret spices to cook high-quality soup for 6 hours every day to serve with their signature dish, Mao Cai. They also provide handmade chili powder dipping sauce, making their dishes uniquely flavorful. Their popular menu items include beef, pig brain, big carp, beef tendon, chicken feet, and pork ribs.`,
    dishs: [
      {
        dishName: "鲜牛肉",
        dishNameEn: "Fresh Beef",
        cover: "/sheet3/23.23-0-1.jpg",
        description:
          "每日选用现杀黄牛的里脊肉作为原料，用适量的调味料，红油，鸡蛋清腌制而成，入口细嫩，回味悠长。",
        descriptionEn:
          `For their fresh beef, they use the tenderloin of freshly slaughtered yellow cattle, marinated with the right amount of seasoning, red oil, and egg white to achieve a tender and long-lasting taste.`,
      },
    ],
    address: " 成都市郫都区蜀源大道五段钓鱼村公交站南行70米",
    addressEn:
      "70 meters south of the Diaoyu Village bus stop, Section 5 of Shuyuan Avenue, Pidu District, Chengdu",
    phone: "13982173261",
    tel: "13982173261",
    longLatitude: "103.87,30.92",
    longLatitudeId: "B0FFGAQLK6",
    left: 546,
    top: 126,
    width: 66,
    height: 54,
  },

  {
    id: "毛氏梭边鱼",
    rName: "毛氏梭边鱼",
    rNameEn: "Mao’s Channel Catfish Restaurant",
    cover: ["/sheet3/24-1-1.jpg"],
    description:
      "毛式梭边鱼位于历史悠久、具有天府水源地之称的唐昌古镇，是一家以香辣虾、香辣蟹和火锅鱼为主打菜品的十年老店。只选用新鲜的基围虾和缅甸铁蟹来保证食物的鲜美，同时加入独家秘制配方。通过高温爆炒的方式，基围虾外皮酥脆、内里嫩滑、香味悠长，展现了四川特色菜肴独有的魅力。",
    descriptionEn:
      `Mao’s Channel Catfish Restaurant is located in the Tangchang Ancient Town, known for its long history and as a source of Tianfu water. It is a decade-old restaurant that specializes in spicy shrimp, spicy crab, and hot pot fish. They only choose fresh shrimps and Myanmar iron crabs to ensure the freshness of their food, and they add exclusive secret ingredients to enhance the flavor. By stir-frying the crayfish at high temperatures, the outer skin of shrimp becomes crispy, while the inside remains tender and smooth, with a long-lasting and delicious aroma that fully showcases the unique charm of Sichuan cuisine.`,
    dishs: [
      {
        dishName: "香辣虾（蟹）",
        dishNameEn: "Spicy Shrimp (Crab)",
        cover: "/sheet3/24-0-1.jpg",
        description:
          "香辣虾（蟹）是中华菜系中的代表性川菜，通常用鲜虾（蟹）作为主要食材，从中间划开表皮，清除内脏，活水冲洗干净后用辣椒和花椒做成底料，加入葱姜蒜等辅料，猛火爆炒而成，以麻、辣、香的独特口感和味道为特点，受到食客们的欢迎。",
        descriptionEn:
          `Their signature dish, Spicy Shrimp (Crab), is a representative Sichuan cuisine in Chinese cuisine. It is usually made with fresh shrimp (crab) as the main ingredient, where the shell is cut open, and the internal organs are removed before being thoroughly washed in clean water. Then, it is cooked with chili and Sichuan pepper to make the base sauce, along with other ingredients such as scallions, ginger, and garlic,then stir-fried over high heat to create a unique flavor characterized by numbing, spicy, and fragrant tastes, making it popular among diners. `,
      },
    ],
    address: "成都市郫都区唐昌镇东方红路27~28号",
    addressEn: "at No. 27-28, Dongfanghong Road, Tangchang Town, Pidu District, Chengdu",
    phone: "15982238320",
    tel: "15982238320",
    longLatitude: "103.88,30.90",
    longLatitudeId: "B0FFF3NOB0",
    left: 600,
    top: 177,
    width: 78,
    height: 66,
    belong: "唐昌镇",
    belongEn: "Tangchang Town",
  },

  {
    id: "老城墙风吹吹",
    rName: "老城墙风吹吹",
    rNameEn: "Ancient City Wall Feng Chui Chui",
    cover: ["/sheet3/25-1-1.jpg"],
    description:
      "老城墙是唐昌标志性建筑，城墙下是曾经的西南马道—古时候的战略防御通道及通商马道，如今的城墙根下已是美食街，里面有多家唐昌特有的“风吹吹”。“风吹吹”是唐昌对火锅的专属称谓，不同于传统火锅，风吹吹锅底更加清淡，还略微带有一种中药材的苦甜之味，且锅底不收费，一盘盘荤素类菜品都摆在保鲜柜里面，供顾客直观选择。",
    descriptionEn: `The ancient city wall is a distinctive landmark of Tangchang. Underneath the wall, there used to be the Southwest Horse Road—an ancient strategic defense and trade route. Nowadays, beneath the city wall, there is a food street where several unique Tangchang specialty "Feng Chuichui" establishments can be found. "Feng Chuichui" is the exclusive term used in Tangchang to refer to hot pot. Unlike traditional hot pots, the soup base of Feng Chuichui is lighter and slightly infused with a bitter-sweet flavor of Chinese medicinal herbs. Moreover, there is no charge for the soup base, and a variety of meat and vegetable dishes are displayed in refrigerated cabinets for customers to choose directly.`,
    dishs: [
      {
        dishName: "麻辣牛肉",
        dishNameEn: "Spicy Beef",
        cover: "/sheet3/25-0-1.jpg",
        description:
          "选用牛腿肉，切成大片，用秘制的腌料腌制20分钟，然后调上彭海椒面，把牛肉放在辣椒里面，用锤子使劲敲打，这样做出来牛肉，有嚼劲，令喜欢吃辣的朋友垂“涎”三尺。",
        descriptionEn:
          `Spicy beef is made with selected beef leg, sliced into large pieces, marinated in a secret sauce for 20 minutes, then mixed with Peng's chili powder. The beef is then placed in the chili and hammered to give it a chewy texture, making it a favorite among spicy food lovers.`,
      },
      {
        dishName: "蔬菜凉粉",
        dishNameEn: "Vegetable jelly",
        cover: "/sheet3/25-0-2.jpg",
        description: "由青菜汁和豌豆粉制作而成，青绿剔透、清热解毒、润肠通便。",
        descriptionEn:
          `It is made from vegetable juice and pea flour, are translucent and vibrant green, known for their cooling properties, detoxification effects, and aiding in digestion`,
      },
    ],
    address: "成都市郫都区唐昌镇南西巷42号",
    addressEn: " No. 42, Nanxi Lane, Tangchang Town, Pidu District, Chengdu",
    phone: "13541013572",
    tel: "13541013572",
    longLatitude: "103.82,30.92",
    longLatitudeId: "B0I17XWNX4",
    left: 288,
    top: 69,
    width: 87,
    height: 78,
    belong: "唐昌镇",
    belongEn: "Tangchang Town",
  },
  {
    id: "蒜乡味庭·鲍鱼鸡",
    rName: "蒜乡味庭·鲍鱼鸡",
    rNameEn: "Garlic Flavor Featured Restaurant. Abalone with Chicken",
    cover: ["/sheet3/26-1-1.jpg"],
    description:
      "蒜乡味庭·鲍鱼鸡位于东林村，以“鲍鱼鸡”为特色，采用散养土鸡、深海鲍鱼让村民足不出户就能吃到美味健康的海鲜养身汤锅，成为“东林村地标美食”，来东林村必去的打卡点。",
    descriptionEn:
      `Garlic Flavor Featured Restaurant. Abalone with Chicken is located in Donglin Village, offering a unique specialty dish known as "Abalone with Chicken." By utilizing local chickens and deep-sea abalones, villagers can enjoy a delicious and healthy seafood hotpot without leaving their homes. This has become a landmark delicacy in Donglin Village and is a must-visit destination for anyone coming to the village.`,
    dishs: [
      {
        dishName: "鲍鱼鸡",
        dishNameEn: "Chicken with Abalone",
        cover: "/sheet3/26-0-1.jpg",
        description:
          "精选300天以上散养土鸡，搭配鲜活鲍鱼、虾，以8小时秘制现熬汤汁熬制，先喝金汤，后食鸡鲍，享极品味蕾，还具有提高免疫力、强身健体、润肺益气的功效。",
        descriptionEn: `Carefully selected local chickens raised for over 300 days are paired with fresh abalone and shrimp. The broth is meticulously crafted through an 8-hour secret process, with the golden broth savored first, followed enjoying the chicken and abalone. This culinary experience offers an exquisite delight to the taste buds, while also providing benefits such as boosting immunity, strengthening the body, and nourishing the lungs and Qi.`,
      },
    ],
    address: "德源街道东林村12组18号",
    addressEn: "No. 18, Group 12, Donglin Village, Deyuan Street, Pidu District.",
    phone: "13540188937",
    tel: "13540188937",
    longLatitude: "103.87,30.75",
    longLatitudeId: "B0G0DA8SME",
    left: 350,
    top: 610,
    width: 129,
    height: 72,
  },
];
