import { sheet1 } from "./sheet1";
import { sheet2 } from "./sheet2";
import { sheet3 } from "./sheet3";
import { sheet4 } from "./sheet4";
import { sheet5 } from "./sheet5";
import { sheet6 } from "./sheet6";
import { sheet7 } from "./sheet7";

export const allSheets = [
  ...sheet1,
  ...sheet2,
  ...sheet4,
  ...sheet5,
  ...sheet3,
  ...sheet6,
  ...sheet7,
];

let allDouBanStore = [];
sheet5.map((item) => {
  allDouBanStore = [...allDouBanStore, ...item.restaurants];
});

export const navMap = {
  导航: [
    {
      name: "餐饮名店",
      nameEn: "Famous Restaurants",
      data: sheet1,
    },
    {
      name: "特色小店",
      nameEn: "Specialty Store",
      data: sheet2,
    },
    {
      name: "网红餐厅",
      nameEn: "Influencer Restaurant",
      data: sheet3,
    },
  ],
  市级名菜: [
    {
      name: "市级名菜",
      nameEn: "The famous dishes of Chengdu from Pidu district",
      data: sheet4,
    },
  ],
  郫县豆瓣宴: [
    {
      name: "郫县豆瓣宴",
      nameEn: "Pixian Douban banquet",
      data: sheet5,
      storeList: allDouBanStore,
    },
  ],
  美食聚集区: [
    {
      name: "美食聚集区",
      nameEn: "Food accumulation area",
      data: sheet6,
    },
  ],
  重点消费场景: [
    {
      name: "重点消费场景",
      nameEn: "Recommended places to consume",
      data: sheet7,
    },
  ],
};
