<template>
  <div class="dialog-wrraper">
    <img src="@/assets/dialog/close.png" class="close-icon" @click="goBack" />
    <div class="dialog-contaner">
      <div class="dialog-contaner-content">
        <img src="@/assets/home/panda_left.gif" class="panda" />
        <div class="dialog-tilte">{{ isEnglish ? (detail.rNameEn || detail.dishNameEn) : (detail.rName || detail.dishName)
        }}</div>
        <div class="info-box" ref="infoBox">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in detail.cover" :key="index">
              <van-image lazy-load class="swipe-item" fit="cover" :src="BASE_URL + item"
                @click="preview(detail.cover, index)" />
            </van-swipe-item>
          </van-swipe>
          <div class="description">{{ isEnglish ? detail.descriptionEn : detail.description }}</div>
          <div class="tel-content" v-if="detail.phone">
            <div class="sub-title">{{ isEnglish ? 'Contact number：' : '联系电话：' }}<a class="tel"
                :href="'tel:' + detail.phone">{{ detail.phone }}</a></div>
          </div>
          <div class="dishs-content" v-if="detail.dishs && detail.dishs.length > 0">
            <div class="sub-title">{{ isEnglish ? 'Featured dishes：' : '特色菜：' }}</div>
            <div class="dishs-list">
              <div class="dishs-item" v-for="(item, index) in detail.dishs" :key="index">
                <van-image lazy-load class="dishs-img" fit="cover" :src="BASE_URL + item.cover"
                  @click="preview([item.cover])" />
                <div class="dishs-name">{{ isEnglish ? item.dishNameEn : item.dishName }}</div>
                <div class="dishs-des">{{ isEnglish ? item.descriptionEn : item.description }}</div>
              </div>
            </div>
          </div>
          <div class="dishs-content" v-if="detail.restaurants && detail.restaurants.length > 0">
            <div class="sub-title">{{ isEnglish ? 'Featured stores' : '特色店：' }}</div>
            <div class="dishs-list">
              <div class="dishs-item" v-for="(item, index) in detail.restaurants" :key="index">
                <van-image lazy-load class="dishs-img" fit="cover" :src="BASE_URL + item.cover"
                  @click="preview([item.cover])" />
                <div class="dishs-name store-name">
                  <a :href="'https://www.gaode.com/place/' + item.longLatitudeId" target="_blank"
                    class="location"></a>
                  <div class="name">{{ isEnglish ? item.rNameEn : item.rName }}</div>
                  <img src="@/assets/dialog/location.png" class="location-icon">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="go" v-if="!detail.restaurants">
        <a :href="'https://www.gaode.com/place/' + (detail.longLatitudeId || detail.restaurants[0].longLatitudeId)"
          target="_blank" class="location"></a>
        <img v-if="isEnglish" src="@/assets/dialog/go_en.png" @click="goHere" class="go-icon" />
        <img v-else src="@/assets/dialog/go.png" @click="goHere" class="go-icon" />
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "@/const/const";
import { ImagePreview } from 'vant';

export default {
  name: "DetailDialog",
  props: {
    detail: {
      type: Object,
      default: {}
    },
    isEnglish: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.$refs.infoBox.scrollTop = 0;
      }
    }
  },
  data() {
    return {
      BASE_URL
    };
  },
  methods: {
    preview(imgs, index = 0) {
      const images = []
      imgs.map(item => {
        images.push(BASE_URL + item)
      })
      ImagePreview({
        images,
        startPosition: index,
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    makeCall() {
      window.location.href = this.detail.phone;
    },
    goHere() {
      // ios打开苹果地图
      // const url = `http://maps.apple.com/?q=${this.detail.address || this.detail.restaurants[0].address}&sll=${this.detail
      //   .longLatitudeId || this.detail.restaurants[0].longLatitudeId}&z=10&t=s`;
      // window.location.href = url;
    }
  },
  created() { },
  mounted() { }
};
</script>
<style lang="less" scoped>
.dialog-wrraper {
  position: relative;
  display: inline-block;
  width: 305rem;
  height: 498rem;
  background-color: transparent;
  text-align: center;

  .close-icon {
    width: 25rem;
    float: right;
    margin-bottom: 20rem;
  }

  .go {
    position: relative;
    width: 145rem;
    height: 48rem;
    margin: 0 auto;
    margin-top: 30rem;
  }

  .location {
    position: absolute;
    z-index: 15;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
  }

  .go-icon {
    width: 145rem;
    height: 48rem;
  }
}

.dialog-contaner {
  text-align: center;

  .dialog-bg {
    margin: auto;
  }

  .dialog-contaner-content {
    margin: auto;
    background-image: url(~@/assets/dialog/dialog_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 305rem;
    height: 430rem;
    overflow-y: scroll;
    box-sizing: border-box;
    position: relative;
    .panda {
      width: 30rem;
      position: absolute;
      right: 10rem;
      bottom: 4rem;
      z-index: 11;
    }

    .dialog-tilte {
      padding: 9rem 0 9rem 20rem;
      font-size: 16rem;
      color: #ffffff;
      text-align: left;
    }

    .info-box {
      height: 335rem;
      overflow-y: scroll;
      padding: 0 20rem 20rem;
    }

    .description {
      font-size: 12rem;
      color: #fbffff;
      line-height: 21rem;
      text-align: justify;
      padding: 8rem 0;
    }

    .sub-title {
      font-weight: 600;
      font-size: 16rem;
      color: #61fffd;
      text-align: left;
      padding-bottom: 8rem;

      .tel {
        text-decoration: underline;
        line-height: 16rem;
        font-size: 12rem;
        color: #caf4ff;
      }
    }

    .swipe {
      margin: 10rem 0 0 0;

      .swipe-item {
        height: 165rem;
        width: 100%;

        /deep/.van-image__img {
          border-radius: 6rem;
        }
      }
    }
  }

  .dishs-content {
    .dishs-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .dishs-item {
        margin-bottom: 10rem;
        width: 265rem;
        position: relative;

        .dishs-img {
          width: 265rem;
          height: 165rem;
          border-radius: 6rem;
          overflow: hidden;
        }

        .dishs-name {
          text-align: center;
          padding: 5rem 0 10rem;
          color: #fff;
          font-size: 12rem;
          font-weight: 500;
          word-break: break-all;
          text-overflow: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;

          &.store-name {
            text-align: left;
            position: relative;
            display: flex;
            justify-content: space-between;

            .location-icon {
              width: 25rem;
              margin-left: 20rem;
            }
          }
        }

        .dishs-des {
          text-align: justify;
          padding-bottom: 20rem;
          color: #fff;
          font-size: 12rem;
          text-indent: 24rem;
        }
      }
    }
  }
}
</style>
