import Vue from "vue";
import App from "./App.vue";
import rem from "./utils/rem";
import "vant/lib/index.css";
import {
    Popup,
    Button,
    Image as VanImage,
    Swipe,
    SwipeItem,
    Lazyload,
} from "vant";

Vue.config.productionTip = false;

Vue.use(VanImage)
    .use(Swipe)
    .use(SwipeItem)
    .use(Button)
    .use(Popup)
    .use(Lazyload);

new Vue({
    render: (h) => h(App),
}).$mount("#app");
