export const sheet2 = [
  {
    id: "益丰和号",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "益丰和号",
    rNameEn: "Yifenghe Soybean Paste Store",
    cover: ["/sheet2/1-1-1.jpg"],
    description:
      "“益丰和号”是中华老字号“鹃城牌”郫县豆瓣的门市，其前身是“益丰和号”酱园，建于清朝咸丰年间。作为郫县豆瓣历史考证发源地，“益丰和号”见证了郫县豆瓣三百余年风雨征程，其制作技艺被列为国家级非物质文化遗产。",
    descriptionEn:
      `"Yifenghe Soybean Paste Store" is a storefront of "Juancheng Brand" Pixian Douban, a time-honored Chinese brand. Its predecessor, "Yifenghe" Soybean Paste Factory, was built during the Xianfeng period of the Qing Dynasty. As the historical source of Pixian Douban, "Yifenghe" has witnessed the ups and downs of Pixian Douban for more than 300 years, and its production techniques have been listed as a national-level intangible cultural heritage.`,
    address: "成都市郫都区郫筒街道南大街41号",
    addressEn: "No. 41, South Street, Pitong Street, Pidu District, Chengdu",
    phone: "13684007661",
    person: "赵  鸣",
    tel: "13684007661",
    longLatitude: "103.88,30.81",
    longLatitudeId: "B001C8TY00",
    left: 738,
    top: 435,
    width: 39,
    height: 48,
    dishs: [
      {
        dishNameEn: "Pixian Special Grade Soybean Paste",
        dishName: "郫县特级豆瓣酱",
        cover: "/sheet2/1-0-1.jpg",
        description:
          "郫县豆瓣是“川菜之魂”，是不可或缺的味蕾刺激。“益丰和号”特级郫县豆瓣酱作为鹃城牌郫县豆瓣的高端产品，不仅是色香味俱全的高级调味品，还是中国地道技术的传承。一盒特级郫县豆瓣凝结了郫县豆瓣人的智慧和“匠人精神”，精选优质食材作为原料，经四道传统工艺打造，经历三至五年精心酿制才算完成。",
        descriptionEn: `Pixian Douban, known as the "soul of Sichuan cuisine," is an essential flavor enhancer. "Yifenghe"  Special Grade Soybean Paste, as a high-end product of Pixian Douban under the brand "Juancheng," is not only a premium seasoning with excellent color, aroma, and taste but also represents the inheritance of authentic Chinese culinary techniques. Each box of Special Grade Pixian Douban embodies the wisdom and craftsmanship of Pixian Douban artisans. It is made from carefully selected high-quality ingredients, undergoes a traditional four-step process, and is meticulously brewed for three to five years to achieve its full flavor.`,
      },
    ],
  },

  {
    id: "清真丁鹅",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "清真丁鹅",
    rNameEn: "Halal Ding's Goose",
    cover: ["/sheet2/2-1-1.jpg"],
    description:
      "清真丁鹅成立至今已有40年历史，创始人姓丁，名德源,回族人,故店名取为“清真丁鹅”，是一家专门制作经营盐水、烟熏鹅、牛肉和兔子等清真食品的店铺。丁鹅一直以来秉承“良心为本、诚信经营”的理念，所选食材均为绿色、健康、纯天然的，不添加色素、糖精、味精。规范的制作程序,严格的用料和卫生标准,优良的质量,诚信的价格和周到的服务,受顾客的长期信赖和支持。",
    descriptionEn:
      `Halal Ding's Goose has a history of 40 years since its establishment. The founder, Ding Deyuan, is a member of the Hui ethnic group, hence the name "Halal Ding's Goose". It is a shop specializing in the production and sale of halal foods such as brine, smoked goose, beef, and rabbit. Halal Ding's Goose has always adhered to the principles of "integrity-based, honest operation." They carefully select green, healthy, and natural ingredients, without adding artificial colors, saccharin, or monosodium glutamate (MSG). With standardized production processes, strict ingredient selection, hygiene standards, excellent quality, honest pricing, and thoughtful service, they have gained the long-term trust and support of their customers.`,
    address: "成都市郫都区郫筒街道西大街252号",
    addressEn: "No. 252, West Street,Pitong Street, Pidu District, Chengdu",
    phone: "13096305994",
    person: "刘学军",
    tel: "Liu 13096305994",
    longLatitude: "103.88,30.81",
    longLatitudeId: "B0FFFAB6PH",
    left: 794,
    top: 457,
    width: 34,
    height: 41,
    dishs: [
      {
        dishName: "烟熏板鹅",
        dishNameEn: "Smoked Goose",
        cover: "/sheet2/2-0-1.jpg",
        description:
          "选用健壮成年的优质鹅(老鹅),从不使用激素健养的鹅,遵循清真食品要求，专人活物屠宰,宰后的老鹅经盐腌渍一晚后，用木柴和锯末进行慢火熏燎，再用独特的家制陈年老卤汤卤制。成品后的板鹅形体宽展，色泽暗红而红亮，具有浓郁的传统烟熏酱香味，在众多腌卤食品中自成一格。",
        descriptionEn: `Only robust and mature high-quality geese (old geese) are selected, without the use of hormone-fed geese. Following halal food requirements, the geese are slaughtered by dedicated personnel. After slaughter, the old geese are salted and marinated overnight. They are then slowly smoked over a fire of wood and sawdust, and finally braised in a unique homemade aged brine. The finished plank goose has a wide and well-shaped body, with a dark red and glossy color. It possesses a rich traditional smoky aroma and flavor that sets it apart from other cured and braised foods.`,
      },
    ],
  },

  {
    id: "徐记酥肉豆花",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "徐记酥肉豆花",
    rNameEn: "Xu’s Tofu Pudding with Crispy Pork",
    cover: ["/sheet2/3-1-1.jpg"],
    description:
      "徐记酥肉豆花制作工艺传承于下乡知青彭孝清。1979年，彭孝清在两路口小吃店制作销售面食、豆花、肥肠粉、汤圆等食品。1999年，两路口小吃店解散后，彭孝清带着儿子徐贵强在郫筒镇创立徐记酥肉豆花，售卖酥肉豆花、肥肠粉、面食等一类小吃，生意红火。其中，酥肉豆花和肥肠粉味道咸鲜麻辣、口感软糯香脆、深受食客喜欢。",
    descriptionEn:
      `The production techniques of Xu’s Tofu Pudding with Crispy Pork are inherited from Peng Xiaoqing, a young intellectual who was sent down to the countryside during the Cultural Revolution. In 1979, Peng Xiaoqing produced and sold various foods such as noodles, douhua (tofu pudding), feichangfen (rice noodles with pork intestines), and tangyuan (sweet glutinous rice balls) at a snack bar in Lianglukou. After the snack bar was dissolved in 1999, Peng Xiaoqing and his son Xu Guiqiang established Xu’s Tofu Pudding with Crispy Pork in Pitong Town, Pixian County. They sold snacks such as tofu pudding with crispy pork, feichangfen, and noodles, and business was booming. Among them, the Tofu Pudding with crispy pork and feichangfen have a savory, spicy, and numbing flavor, and a soft, chewy, and crispy texture, which is deeply loved by diners.`,
    address: "成都市郫都区郫筒街道宝生街131号",
    addressEn: "No.131, Baosheng Street, Pitong Street, Pidu District, Chengdu",
    phone: "13551300130",
    person: "徐贵强",
    tel: "Xu 13551300130",
    longLatitude: "103.88,30.81",
    longLatitudeId: "B0FFGMA18F",
    left: 774,
    top: 506,
    width: 46,
    height: 31,
    dishs: [
      {
        dishName: "酥肉豆花",
        dishNameEn: "Tofu Pudding with Crispy Pork",
        cover: "/sheet2/3-0-1.jpg",
        description:
          "酥肉豆花以酥肉、黄豆、撒子、海带等配料打底，加入各种调味品和红油辣子，舀入热腾腾的粉汤，再铺一层豆花，撒上葱花香菜，色胜白玉、滑嫩爽口、鲜香咸辣。",
        descriptionEn: `this dish is made with crispy pork, yellow soybeans, pickled peppers, seaweed, and other ingredients as the base. It is seasoned with various spices and chili oil, served in a steaming hot broth, topped with tofu pudding, and garnished with spring onions and cilantro. It boasts a visually appealing presentation, smooth and tender texture, and a delightful combination of freshness, saltiness, and spiciness.`,
      },
    ],
  },

  {
    id: "杨鸡肉川味寻香体验店",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "杨鸡肉川味寻香体验店",
    rNameEn: "Yang's Chicken Sichuan Flavor Seeking Fragrance Experience Store",
    cover: ["/sheet2/4-1-1.jpg"],
    description:
      "杨鸡肉由原郫县新民场镇云桥村党支部书记杨远福创立。1996年，杨远福开办“农家乐”，主要经营手工石磨豆花、粑粑菜、回锅肉、时鲜蔬菜和凉拌鸡肉等农家菜。其中，凉拌鸡肉以农家自养土鸡为原料，配上天然优质佐料，再加上杨家祖传的制作方法，成品鲜香可口，深受食客喜爱，被食客美誉为“杨鸡肉”，热卖27年。",
    descriptionEn:
      `Yang's Chicken is founded by Yang Yuanfu, the secretary of the Party branch of Yunqiao Village, Xinminchang Town, Pidu District (former Pixian County). In 1996, Yang Yuanfu opened a farmhouse-style restaurant（agritainment）, mainly offering handmade stone-milled douhua (tofu pudding), papa cai (vegetables cooked with broad bean sauce), twice-cooked pork, fresh vegetables, and cold chicken. Among them, the cold chicken is made from locally raised free-range chickens, mixed with natural and high-quality ingredients, and made with Yang's ancestral production method. The finished product is fresh, fragrant, and delicious, and is deeply loved by diners. It has been known as "Yang Chicken" and has been selling well for 27 years.`,
    address: "成都市郫都区郫筒街道望丛中路498号",
    addressEn: "No. 498, Midlle Wangcong Road, Pitong Street, Pidu District, Chengdu",
    phone: "18034707380",
    person: "杨家瑞",
    tel: "Yang 18034707380",
    longLatitude: "103.89,30.80",
    longLatitudeId: "B001C0287D",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
    dishs: [
      {
        dishName: "杨鸡肉",
        dishNameEn: `Yang's Chicken`,
        description: `杨氏鸡肉的制作佐料选用的是二荆条辣椒、汉源花椒、彭县小黄姜等无公害原料。鸡肉又分红味、白味两种。红味与一般凉拌鸡肉相比，不加姜、葱、蒜、酱油之类，只淋上杨家独制的汤汁，然而麻辣、香味尽在其中。白味不带辣味，有盐水鸡和白砍鸡的味道，色泽米白、皮滋肉嫩、咸香味宜、清淡爽口。鸡肉入口味纯化渣，深受消费者喜爱，尤合老人、儿童口味。`,
        descriptionEn: `Yang's Chicken is made by using environmentally-friendly ingredients such as Erjingtiao chili peppers, Hanyuan peppercorns, and Pengxian small yellow ginger. The chicken comes in two varieties: red flavor and white flavor. The red flavor, unlike typical cold-dressed chicken, does not include ingredients like ginger, scallions, garlic, or soy sauce. It is simply drizzled with Yang's homemade broth, which brings out the numbing spiciness and fragrant flavors. The white flavor, on the other hand, is non-spicy and resembles the taste of saltwater chicken or poached chicken. It has a pale, tender skin, a delicate balance of saltiness and fragrance, and a refreshing and mild taste. The chicken is enjoyed for its pure and tender texture, making it particularly suitable for the preferences of older people and children.`,
        cover: "/sheet2/4-0-1.jpg",
      },
    ],
  },

  {
    id: "周鹅腌卤店",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "周鹅腌卤店",
    rNameEn: "Chow's Fried Goose",
    cover: ["/sheet2/5-1-1.jpg"],
    description:
      "“周鹅腌卤店”主营油烫鹅及附属品。“周鹅”秉承“绿色、环保、营养、卫生”的宗旨,传承郫都特色餐饮文化之精髓,凭借严格的用料与卫生标准、优良的品质、诚信的价格及周到的服务,受到顾客的长期信赖和支持。",
    descriptionEn:
      `Chow's Fried Goose specializes in oil-seared goose and accompanying products. "Chow’s Fried Goose" adheres to the principles of "green, environmentally friendly, nutritious, and hygienic," inheriting the essence of the distinctive food culture of Pidu, and relying on strict ingredient and hygiene standards, excellent quality, honest prices, and attentive service. It has earned long-term trust and support from customers.`,
    address: "成都市郫都区郫筒街道南大街真武巷34-36号",
    addressEn: "No. 34-36, Zhenwu Lane, South Street, Pitong Street, Pidu District, Chengdu",
    phone: "周元辅18180655003",
    person: "周元辅",
    tel: "Zhou 18180655003",
    longLatitude: "103.88,30.81",
    longLatitudeId: "B001C91TR3",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
    dishs: [
      {
        dishName: "油烫鹅",
        dishNameEn: "Fried Goose",
        description: `油烫鹅制作程序严谨,配方科学独特,技艺娴熟。烹制时,原料先出水,入锅后旺火卤片刻,再改用小火慢卤。熟后捞起晾冷,抹上饴糖将整个原料用抓子抓住,不入油锅,置锅上用滚油反复淋烫至成品。`,
        descriptionEn: `The process of preparing sizzling hot goose is rigorous, with a unique and scientifically formulated recipe and skilled techniques. During the cooking process, the raw ingredients are blanched in water first, then briefly boiled in a high flame with the marinade before being simmered over low heat. Once cooked, the goose is lifted out and left to cool. It is then coated with malt sugar and grasped with tongs, not being directly placed in the oil pan. The hot oil is continuously poured over the goose until it reaches the desired final texture and appearance.`,
        cover: "/sheet2/5-0-1.jpg",
      },
    ],
  },

  {
    id: "丽妹肺片",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "丽妹肺片",
    rNameEn: 'Limei Restaurant',
    cover: ["/sheet2/6-1-1.jpg"],
    description:
      "“丽妹肺片”创立于1992年，是本地凉菜界知名品牌，在中国（成都）第十届美食节上被评为名优特色食品。主营各类腌卤食品及凉菜系列，招牌为丽妹肺片，菜品包含卤牛肉、凉拌鸡、凉拌兔、无骨凤爪、糖醋排骨等。",
    descriptionEn: `Limei Restaurant was founded in 1992 and is a well-known brand in the local cold dishes industry. It was awarded as a famous and distinctive food at the 10th Chengdu Food Festival in China. The main products are various marinated dishes and cold dishes, with the signature dish being Li Mei Beef Lung Slices. Other dishes include braised beef, cold chicken salad, cold rabbit salad, boneless chicken feet, sweet and sour spare ribs.`,
    address: "成都市郫都区郫筒街道红瓦街133号",
    addressEn: "No.133 Hongwa Street, Pitong Street, Pidu District, Chengdu",
    phone: "13608084617",
    person: "兰  丽",
    tel: "Lan 13608084617",
    longLatitude: "103.89,30.80",
    longLatitudeId: "B0FFHBAQ06",
    left: 704,
    top: 537,
    width: 23,
    height: 25,
    dishs: [
      {
        dishName: "丽妹肺片",
        dishNameEn: "LiMei Beef Lung Slices",
        description: `以牛肚子为主的牛杂煮来火候恰当，耙软合适。片成片的牛肚、牛肝、牛心、牛头皮片形漂亮，质地新鲜，按顾客的需求随意搭配。在丽妹麻利的动作中，各道佐料迅速汇入，随着原汤原汁和红油的淋入，随着香菜、芹菜、葱节、花生米的加入，一份香味浓烈、颜色红亮，色彩鲜艳的肺片送到顾客手中。`,
        descriptionEn: `The main ingredient of the beef offal stew is the beef tripe, cooked to the perfect tenderness and texture. Sliced pieces of beef tripe, liver, heart, and head skin are beautifully arranged, with a fresh and pleasing texture, allowing for customizable combinations based on the customers' preferences. With the swift and skilled actions of the chef, various seasonings are quickly added. As the original broth and sauce are poured in, along with the addition of red chili oil, cilantro, celery, scallion sections, and peanuts, a plate of aromatic and vibrant lung slices is served to the customers, bursting with intense flavors and bright colors.`,
        cover: "/sheet2/6-0-1.jpg",
      },
    ],
  },

  {
    id: "张记砂锅店",
    belong: "犀浦街道",
    belongEn: "Xipu Street",
    rName: "张记砂锅店",
    rNameEn: `Zhang's Casserole`,
    cover: ["/sheet2/7-1-1.jpg"],
    description:
      "“张砂锅”创立于1999年，店内砂锅肥肠、砂锅牛肉等菜品深受食客好评。2014年和2015年连续成为四川电视台美食节目（吃八方）推荐美食，2019年入选成都烹饪协会、成都餐饮同业公会等机构联合发布的百家成都特色小店。",
    descriptionEn:
      `Zhang's Casserole was founded in 1999, and its signature dishes, such as clay pot pork intestine and clay pot beef, have received high praise from diners. In 2014 and 2015, it was recommended as a must-try food by the Sichuan TV food program "Eating Everywhere." In 2019, it was selected as one of the hundred featured Chengdu specialty shops jointly released by the Chengdu Culinary Association and the Chengdu Catering Industry Association.`,
    address: "成都市郫都区犀浦街道上街177号",
    addressEn: "No. 177, Upper of Xipu Street, Pidu District, Chengdu ",
    phone: "13708026727",
    person: "张国林",
    tel: "Zhang 13708026727",
    longLatitude: "103.97,30.76",
    longLatitudeId: "B001C8MEUM",
    left: 1168,
    top: 633,
    width: 38,
    height: 37,
    dishs: [
      {
        dishName: "砂锅牛肉",
        dishNameEn: "Clay Pot Beef",
        description: `滑嫩的牛肉片，入口细腻，鲜香麻辣。`,
        descriptionEn: `Tender beef slices that are smooth and delicate, offering a fine texture and a delightful combination of freshness, fragrance, and spiciness.`,
        cover: "/sheet2/7-0-1.jpg",
      },
    ],
  },

  {
    id: "玉笼轩芙蓉包",
    belong: "安德街道",
    belongEn: "Ande Street",
    rName: "玉笼轩芙蓉包",
    rNameEn: "Yulongxuan Furong Stuffed Bun",
    cover: ["/sheet2/8-1-1.jpg"],
    description:
      "郫都十大名小吃、成都特色小店，主打由宫廷御厨世代传承的芙蓉包，馅多皮薄、肥瘦相间、鲜嫩多汁，此外有牛肉面、烧麦、蒸饺、叶儿粑等小吃快餐供顾客选择。",
    descriptionEn: `Pidu's top 10 Famous Snacks, a Chengdu specialty small restaurant, featuring the signature Furong Bao (Furong Stuffed Bun) passed down through generations by imperial palace chefs. The buns are known for their thin skin, generous filling, and a perfect balance of lean and fatty meat, resulting in a juicy and tender texture. In addition to Furong Bao, customers can also choose from a variety of other snacks and quick bites such as beef noodles, shaomai(steamed pork dumplings)、 steamed dumplings， and yerba (sticky rice cakes wrapped in leaves).`,
    address: "成都市郫都区安德镇安平东路38号",
    addressEn: "No.38, East Anping Road, Ande Town, Pidu District, Chengdu",
    phone: "13668252110",
    person: "吴天明",
    tel: "Wu 13668252110",
    longLatitude: "103.81,30.86",
    longLatitudeId: "B0FFFQ1YYQ",
    left: 242,
    top: 279,
    width: 116,
    height: 81,
    dishs: [
      {
        dishName: "玉笼轩芙蓉包",
        dishNameEn: `Yulongxuan Furong Stuffed Bun`,
        description: `“玉笼轩芙蓉包”又称小笼包，制作工艺由宫廷御厨世代传下，外皮选自上等精制面粉，内陷挑选新鲜优质肉类，以川菜调味品为依托，根据世代祖传秘方精心调制，具有皮薄光滑，馅多鲜美，味汁浓郁，回味无穷的特点，其质淳朴健康，口感清新自然。`,
        descriptionEn: `Yulongxuan Furong Stuffed Bun, also known as Xiaolong buns, has its production technique passed down through generations from the imperial court chefs. The outer dough is made from fine-quality refined flour, while the filling is carefully selected from fresh and high-quality meats. With the foundation of Sichuan-style seasonings and the meticulous blending of ancestral secret recipes, these buns exhibit thin and smooth skin, abundant and delicious filling, rich flavor, and an endless aftertaste. They are characterized by their simplicity, healthiness, and refreshing and natural texture.`,
        cover: "/sheet2/8-0-1.jpg",
      },
    ],
  },

  {
    id: "杨德怀萝卜干",
    belong: "友爱镇",
    belongEn: "Youai Town",
    rName: "杨德怀萝卜干",
    rNameEn: "Yangdehuai Dried Radish",
    cover: ["/sheet2/9-1-1.jpg"],
    description:
      "杨德怀萝卜干位于郫都区友爱镇农科村景区内，创始人杨德怀老人坚持用最好的原材料、全手工制作制作萝卜干。号称“农科村一绝”的杨德怀萝卜干不仅让吃货们不辞辛劳地驱车前往购买，更是通过网络店铺远销到北京、上海等地，以及泰国、新加坡、俄罗斯等国家。",
    descriptionEn: `Yangdehuai Dried Radish, located in the Nongke Village Scenic Area, Youai Town, Pidu District, is known for its founder, Elder Yang Dehuai, who insists on using the finest ingredients and handmade techniques to produce dried radish. Regarded as a ""unique delicacy of Nongke Village,"" Yang Dehuai's Dried Radish not only attracts food enthusiasts who are willing to travel to purchase it, but also sells through online stores to customers in Beijing, Shanghai, as well as countries such as Thailand, Singapore, and Russia.`,
    address: "成都市郫都区友爱镇农科村景区内",
    addressEn: "The Nongke Village Scenic Area in Youai Town, Pidu District, Chengdu.",
    phone: "13648098663",
    person: "杨德怀",
    tel: "Yang 13648098663",
    longLatitude: "103.80,30.76",
    longLatitudeId: "B0FFF75L0F",
    left: 348,
    top: 552,
    width: 66,
    height: 57,
    dishs: [
      {
        dishName: "萝卜干",
        dishNameEn: "Dried Radish",
        description: `杨德怀对选料颇为挑剔，用作原料的萝卜，选用一年中的十月、冬月、腊月的圆根白萝卜，生长周期足、水分重，集脆、嫩、甜于一体，就连洗萝卜的水，也必须是干净的地下水，为此他专门挖掘了十多米的深井，制作出来的萝卜干色泽红亮、干湿适中、爽脆可口、麻辣适中。`,
        descriptionEn: `Yang Dehuai is extremely meticulous when it comes to ingredient selection. For the radish used as raw material, he chooses round-root white radishes harvested in the months of October, December, and the lunar month of Là (usually in January). These radishes have a complete growth cycle and high moisture content, combining crispness, tenderness, and sweetness. Even the water used to wash the radishes must be clean underground water. To meet this requirement, he specifically dug a well more than ten meters deep,so that the dried radish has a vibrant red color, moderate moisture content, a crunchy and delicious texture, and a well-balanced level of spiciness.`,
        cover: "/sheet2/9-0-1.jpg",
      },
    ],
  },

  {
    id: "唐昌吴鸭子",
    belong: "唐昌镇",
    belongEn: "Tangchang Town",
    rName: "唐昌吴鸭子",
    rNameEn: "Tangchang Wu’s Duck",
    cover: ["/sheet2/10-1-1.jpg"],
    description:
      "“唐昌吴鸭子”创立于1981年，历经4代传承人探索改良，已成为“唐昌板鸭”杰出代表。“唐昌吴鸭子”用料考究，技艺精湛。每只鸭子都严格挑选上等农家散养土鸭，现选现用，保证食材新鲜，锁住鸭肉原味。卤料采用60余味中草药，遵循古方配制而成。在技艺上由吴氏掌门人亲自操作完成，卤汁火候苛刻，卤制时间严格。“唐昌吴鸭子”常居“SCTV四川卫视推荐美食榜”，获得“郫县金牌美食”“郫都区本土最受欢迎的十大美食”等荣誉。",
    descriptionEn:
      `Tangchang Wu’s Duck was founded in 1981 and has gone through four generations of inheritance and improvement, making it an outstanding representative of "Tangchang Duck." The selection of ingredients and the craftsmanship of Tangchang Wu’s Duck are both meticulous and exquisite. Each duck is strictly selected from high-quality free-range ducks raised by rural households, and only fresh ducks are used to ensure the original flavor of the meat is retained. The braising sauce is made from more than 60 types of traditional Chinese medicinal herbs, following ancient recipes. For the technique, the head chef from the Wu family personally perform the process, ensuring strict braising time and temperature control. Tangchang Wu’s Duck has always been listed on the "SCTV Sichuan TV Recommended Food List" and has won honors such as "Pixian Gold Medal Food" and "Top Ten Local Popular Foods in Pidu District."`,
    address: "成都市郫都区唐昌街道西正街190号",
    addressEn: "No.190 Xizheng Street, Tangchang Street, Pidu District, Chengdu.",
    phone: "13880166986",
    person: "吴元刚",
    tel: "Wu 13880166986",
    longLatitude: "103.82,30.93",
    longLatitudeId: "B0FFFVCONG",
    left: 396,
    top: 180,
    width: 66,
    height: 60,
    dishs: [
      {
        dishName: "酱香卤鸭子",
        dishNameEn: "Aromatic Braised Duck",
        description: `吴鸭子的制作程序和传统卤鸭子的制作手法相似，但他之所以独特独特，关键在于两点：鸭子和卤料。鸭子选用新鲜、品质上乘的好鸭子，卤料由精选60多味中草药秘制而成。在制作上，必须掌握好火候和卤制时间，使鸭子卤制均匀，入味适度。 经过一个小时左右的卤制，一只只肥美鲜香的鸭子便从沸腾的卤汁中被拿出来，金黄发亮、色泽诱人、肉质鲜嫩。`,
        descriptionEn: `The preparation process of the Duck is similar to that of traditional braised duck, but its uniqueness lies in two aspects: the duck itself and the braising sauce. Wu's Duck uses fresh, high-quality ducks, and the braising sauce is made from a selection of over 60 secret herbs and spices. In the cooking process, it is crucial to control the heat and braising time to ensure that the duck is evenly braised and properly infused with flavors. After about an hour of braising, the succulent and fragrant ducks are taken out from the boiling braising liquid. They have a golden and shiny appearance, tempting color, and tender meat.`,
        cover: "/sheet2/10-0-1.jpg",
      },
    ],
  },

  {
    id: "朱记牛肉馆",
    belong: "唐昌镇",
    belongEn: "Tangchang Town",
    rName: "朱记牛肉馆",
    rNameEn: "Zhu’s Beef Restaurant",
    cover: ["/sheet2/11-1-1.jpg"],
    description:
      "唐昌朱记牛肉始于1994年，以用料新鲜、考究、无化学添加，原汁原味倍受到八方食客的喜爱。系列菜品精选高山放养、亲自宰杀的黄牛肉，配以20余味香料，辅以独  家秘制配方，历经几道工序精心码味去腥后卤制而成。以色、香、味、形体现出传统技艺的保留与提升，鲜香，有嚼劲，化渣，可口，无销盐、色素。",
    descriptionEn: `Tangchang Zhu’s Beef, established in 1994, is highly favored by food lovers from all over for its use of fresh, exquisite ingredients and the absence of chemical additives, ensuring an authentic and flavorful dining experience. The series of dishes are crafted using carefully selected high-altitude, free-range yellow cattle, which are personally slaughtered. They are then paired with more than 20 aromatic spices and prepared with an exclusive secret recipe. Through several meticulous steps, the flavors are skillfully balanced and the gaminess is removed. The result is a reflection of the preservation and enhancement of traditional techniques, showcasing vibrant colors, enticing aromas, delicious flavors, and appealing presentations. The beef is tender, chewy, easily digestible, and free from excessive salt and artificial coloring.`,
    address: "成都市郫都区唐昌镇战旗村1号153栋",
    addressEn: "No. 153, Building 1, Zhanqi Village, Tangchang Town, Pidu District, Chengdu",
    phone: "1354108297",
    person: "江家友",
    tel: "Jiang 1354108297",
    longLatitude: "103.79,30.94",
    longLatitudeId: "B001C8Z0Y4",
    left: 372,
    top: 27,
    width: 105,
    height: 75,
    dishs: [
      {
        dishName: "蒸牛肉",
        dishNameEn: "Steamed Beef",
        description: `蒸牛肉裹以米粉用心蒸制，鲜嫩滑口，麻辣适中。`,
        descriptionEn: `Steamed beef wrapped in rice flour is carefully steamed, resulting in a tender and smooth texture with a moderate level of spiciness.`,
        cover: "/sheet2/11-0-1.jpg",
      },
      {
        dishName: "牛肉汤",
        dishNameEn: "Beef Soup",
        description: `牛肉汤选用上好牛股腿骨与新鲜牛肉，经8小时微火慢熬而成，口感绵密、汤白味浓、回口留香。`,
        descriptionEn: `The beef soup is made using high-quality beef bones and fresh beef, simmered over low heat for 8 hours. It has a rich and creamy texture, a white appearance, and a lingering flavorful taste.`,
        cover: "/sheet2/11-0-2.jpg",
      },
      {
        dishName: "凉拌肺片",
        dishNameEn: `Spicy Lung Salad`,
        description: `"凉拌肺片"是四川省成都市的一道传统名菜， 色泽红亮、质地软嫩、、麻辣鲜香、软糯爽滑、脆筋柔糜、细嫩化渣。`,
        descriptionEn: `"Spicy Lung Salad" is a traditional famous dish from Chengdu, Sichuan Province, in China. It is characterized by its bright red color, tender texture, spicy and fragrant taste, soft and smooth consistency, and a combination of crunchy and tender elements. The meat is finely sliced and melts in the mouth, creating a delightful culinary experience.`,
        cover: "/sheet2/11-0-3.jpg",
      },
    ],
  },

  {
    id: "杨抄手",
    belong: "唐昌镇",
    belongEn: "Tangchang Town",
    rName: "杨抄手",
    rNameEn: "Yang's Wonton",
    cover: ["/sheet2/12-1-1.jpg"],
    description:
      "“杨抄手”创立于1979年，以“皮薄、汤鲜”著称，历经40余年的摸索改进，红汤抄手“麻、辣、鲜、香”，清汤抄手“汤鲜、馅嫩”，独创的红油水饺更是深受当地百姓和外地食客的喜爱。2021年被成都市商务局评为成都市特色小店。",
    descriptionEn:
      `"Yang's Wonton" was founded in 1979 and is known for its "thin skin and flavorful broth". After more than 40 years of exploration and improvement, the spicy and numbing red broth wonton is "spicy, flavorful, and fragrant", while the clear broth wonton is "fresh and tender". The uniquely created chili oil dumplings are also popular among local residents and out-of-town visitors. In 2021, it was awarded as a characteristic small shop in Chengdu by the Chengdu Municipal Bureau of Commerce.`,
    address: "成都市郫都区唐昌镇西正街52号",
    addressEn: "No. 52, Xizheng Street, Tangchang Town, Pidu District, Chengdu",
    phone: "15002874594",
    person: "周学清",
    tel: "Zhou 15002874594",
    longLatitude: "103.82,30.93",
    longLatitudeId: "B0FFFXB2FC",
    left: 381,
    top: 102,
    width: 75,
    height: 66,
    dishs: [
      {
        dishName: "红油抄手",
        dishNameEn: "Chili Oil Wontons",
        description: `红油抄手汤汁红亮，辣而不燥，混着肉馅入口，十分开胃。`,
        descriptionEn: `The soup of "Chili Oil Wontons" has a bright red color, it is spicy but not overpowering, and when combined with the meat filling, it creates a flavorful and appetizing taste.`,
        cover: "/sheet2/12-0-1.jpg",
      },
    ],
  },

  {
    id: "罗记豆瓣抄手",
    belong: "三道堰镇",
    belongEn: "SanDaoYan Town",
    rName: "罗记豆瓣抄手",
    rNameEn: "Luo’s Wanton",
    cover: ["/sheet2/13-1-1.jpg"],
    description:
      "“罗记豆瓣抄手”制作工艺出自清代罗家。清代名将罗应施，郸县人，作战有功，官至贵东道，荣归故里时，其母亲自做抄手给他吃，在汤中加入自制豆瓣。罗应施吃后赞不绝口，就命夫人习之。罗氏族人世代传承豆瓣抄手制作之法，每逢佳节，族人必以豆瓣抄手为主食，乐享美味。罗氏豆瓣抄手代代相传至今，被评为成都市知名创新小吃，得到四川电视台《吃八方》栏目等多家媒体重点推介。",
    descriptionEn:
      `Luo’s Wanton originated from the Luo family in the Qing Dynasty. Luo Yingshi, a famous general in the Qing Dynasty from Dancheng County, achieved military exploits and became the governor of Guizhou Province. When he returned home in glory, his mother made him a dish of Chao Shou (Wanton) with homemade Dou Ban (spicy bean paste) in the soup. Luo Yingshi was full of praise after eating it and ordered his wife to learn the recipe. The Luo family has passed down the tradition of making soy bean wonton for generations, and it has become a staple food for festivals and a popular delicacy. The Luo family's Douban-flavored wonton has been recognized as a famous innovative snack in Chengdu, and has been prominently featured in various media such as the Sichuan TV program "Eat Everywhere". `,
    address: "成都市郫都区古城街道蜀汉东街125号-127号",
    addressEn: 'No. 125-127, East Shuhan Street, Gucheng Street, Pidu District, Chengdu',
    phone: "13688450151",
    person: "吕小虎",
    tel: "LV 13688450151",
    longLatitude: "103.93,30.89",
    longLatitudeId: "B0FFJA7DBP",
    left: 795,
    top: 171,
    width: 78,
    height: 81,
    dishs: [
      {
        dishName: "豆瓣抄手",
        dishNameEn: "Douban-Flavored Wonton",
        description: `罗记豆瓣抄手因加入了豆瓣酱更具特色，豆瓣酱含丰富的蛋白质和维生素，可延缓动脉硬化，降低胆固醇，促进肠蠕动，增强食欲。豆瓣酱有益气、健脾、利湿、消肿肺结核之功效，同时含有大脑和神经组织的重要组成成份——磷脂，并含有丰富的胆碱，有健脑的作用，可增强记忆力。此外，豆瓣酱也是抗癌食品之一，是降低前列腺增生症及肠癌发病率的食疗佳品。`,
        descriptionEn: `"Luoji wonton" is characterized by the addition of Doubanjiang (soybean paste), which gives it a distinctive flavor. Doubanjiang is rich in protein and vitamins, which can help delay arteriosclerosis, lower cholesterol, promote intestinal peristalsis, and enhance appetite. It has beneficial effects on qi, spleen, dampness elimination, and reducing swelling caused by pulmonary tuberculosis. It also contains phospholipids, an important component of brain and nerve tissues, as well as abundant choline, which promotes brain health and enhances memory. Additionally, Doubanjiang is considered one of the anticancer foods and is beneficial for reducing the incidence of benign prostatic hyperplasia and colorectal cancer.`,
        cover: "/sheet2/13-0-1.jpg",
      },
    ],
  },
];
