export const sheet7 = [
  {
    id: "佛罗伦萨小镇（国际名品购物地）",
    rName: "佛罗伦萨小镇（国际名品购物地）",
    rNameEn: "Florentia Village (an international luxury shopping destination)",
    cover: ["/sheet7/1-1-1.jpg", "/sheet7/1-1-2.jpg"],
    description:
      "佛罗伦萨小镇位于成都市郫都区友爱镇银杏路888号，是中国西南地区首座佛罗伦萨小镇，隶属意大利RDM集团，汇集了包括Armani、Tod’s、Lane Crawford、Brooks Brothers、 Nike在内的200余个享誉国内外的知名品牌，提供全年低至2折的折扣优惠。其中GUCCI、Prada、Valentino、Jimmy Choo、Polo Ralph Lauren等品牌为西南奥莱独有，并在2021年被评为郫都区生活消费新场景。",
    descriptionEn:
      `Florentia Village is located at No. 888, Gingko Road, Youai Town,Pidu District, Chengdu. It is the first Florentia Village in southwest China, owned by Italy's RDM Group. It brings together more than 200 renowned domestic and international brands, including Armani, Tod's, Lane Crawford, Brooks Brothers, and Nike, offering discounts of up to 80% off throughout the year. Brands such as GUCCI, Prada, Valentino, Jimmy Choo, and Polo Ralph Lauren are unique to Southwest Outlets and were awarded as a new consumer scene in Pidu District in 2021.`,
    feature: "国家AAA级旅游景区，意式风情，大牌汇聚，体现首店国际范的消费",
    featureEn:
      "Florentia Village, a national AAA-level tourist attraction, The Italian-style atmosphere and collection of top luxury brands embody an international shopping experience with the presence of first-store exclusives.",
    address: "友爱镇",
    addressEn: "Town YouAi",
    // phone: "刘萍18080030800",
    tel: "LIU 18080030800",
    longLatitude: "103.838,30.8106",
    longLatitudeId: "B0H2C7SVGZ",
    left: 460,
    top: 555,
    width: 94,
    height: 55,
  },
  {
    id: "春天花乐园（花卉美学体验地）",
    rName: "春天花乐园（花卉美学体验地）",
    rNameEn: "Spring Garden Wonderland(a floral aesthetics experience destination)",
    cover: ["/sheet7/2-1-1.jpg"],
    description:
      "春天花乐园位于成都市郫都区团结街道，是一家立足现代农业，植根花卉产业，致力于花卉产业整合运营的现代化企业集团，以“引领产业创新发展，共创花卉美好生活”为使命，13万㎡的国际智能设施化温室、现代化交易园区入驻商户近600户，入驻率100%，同时任中国花卉协会理事、中花协市场流通分会和零售业分会副会长单位，四川省花卉协会副会长单位，成都市花卉协会会长单位。荣获四川省农业产业化省级重点龙头企业、成都市百佳社区商业企业等荣誉。2024年在成都举办的世界园艺博览会，春天花乐园为郫都分会场。",
    descriptionEn:
      `Spring Garden Wonderland is located in Tuanjie Street, Pidu District, Chengdu. It is a modern enterprise group based on modern agriculture and rooted in the floral industry, dedicated to the integration and operation of the floral industry. Its mission is to "lead the innovation and development of the industry, and create a better life with flowers." The park covers an area of 130,000 square meters, with international intelligent facility greenhouses and a modern trading park housing nearly 600 merchants, achieving a 100% occupancy rate. At the same time, it serves as a director of the China Flower Association, vice president unit of the Market Circulation Branch and Retail Branch of the China Flower Association, vice president unit of the Sichuan Flower Association, and president unit of the Chengdu Flower Association. The park has been honored with titles such as the Provincial Key Leading Enterprise in Agricultural Industrialization in Sichuan Province and the Top 100 Community Commercial Enterprises in Chengdu City. In 2024, the Park will serve as the branch venue of Pidu District for the World Horticultural Exposition to be held in Chengdu.`,
    feature: "以花植设计、文创展陈等为底蕴打造集艺术生活与美学空间相融合的创新消费场景。",
    featureEn:
      "The park creates an innovative consumption scene that integrates artistic life and aesthetic space through floral and plant design, creative exhibition, and more.",
    address: "团结街道",
    addressEn: "St.Tuanjie",
    // phone: "董小婉18908227765",
    tel: "Dong 18908227765",
    longLatitude: "103.95,30.83",
    longLatitudeId: "B0G1FUPI93",
    left: 1025,
    top: 370,
    width: 75,
    height: 65,
  },
  {
    id: "成都川菜博物馆（可以“吃”的博物馆）",
    rName: "成都川菜博物馆（可以“吃”的博物馆）",
    rNameEn: 'Chinese Sichuan Cuisine Museum(a museum where you can "eat")',
    cover: ["/sheet7/3-1-1.jpg"],
    description:
    "成都川菜博物馆位于成都市郫都区三道堰镇，是世界唯一以地方菜系文化为陈列内容的活态主题博物馆，是一座可以“吃”的博物馆。馆内分为典藏馆、互动演示馆、灶王祠、郫县豆瓣传统工艺展示区等，在这里不仅可以了解川菜的历史文化，还能体验川菜的刀功火候、色香味形，全方位感受川菜文化魅力。",
    descriptionEn: `Chinese Sichuan Cuisine Museum is located in Sandaoyan Town, Pidu District, Chengdu City. It is the world's only living theme museum that displays local cuisine culture, and is a museum where you can "eat." The museum is divided into a collection hall, interactive demonstration hall, Kitchen God Temple, and Pixian Douban traditional craft exhibition Area. Here, visitors can not only learn about the history and culture of Sichuan cuisine, but also experience the cutting techniques and cooking skills, as well as the colors, aromas, and tastes of Sichuan cuisine, and fully feel the charm of Sichuan cuisine culture. `,
    feature: "国家AAA级旅游景区，体验川菜刀功火候、色香味形，全方位感受川菜文化魅力的可以“吃”的博物馆。",
    featureEn: `As a national AAA-level tourist attraction, The museum is a unique place where visitors can experience the charm of Sichuan cuisine culture by "eating."`,
    address: "三道堰镇",
    addressEn: "Town SanDaoYan",
    // phone: "张建13808023725",
    tel: "Zhang 13808023725",
    longLatitude: "103.93,30.90",
    longLatitudeId: "B001C7U21B",
    left: 715,
    top: 60,
    width: 136,
    height: 100,
  },
  {
    id: "绿城·中国川菜小镇（主题旅游目的地）",
    rName: "绿城·中国川菜小镇（主题旅游目的地）",
    rNameEn: "Greentown · China Sichuan Cuisine Town(Theme tourism destinations)",
    cover: ["/sheet7/4-1-1.jpg"],
    description:
      "绿城·中国川菜小镇位于成都市郫都区安德街道，总占地约10847亩，围绕“川菜文化”与“现代都市田园”两大IP ，以两轴、六心、六区为总体规划，小镇内包括以川菜24种味型为基础、收藏与出售味道的世界味觉博物馆，以“给我一天、吃遍四川”为美食体验的蜀香183，以通过科技互动、冒险游乐等寓教于乐体验的食物冒险乐园等，曾先后被评为国家级“非遗旅游小镇”“2021郫都区生活消费新场景”“新旅游·潮成都”主题旅游目的地等荣誉称号。",
    descriptionEn:
      `The Greentown · China Sichuan Cuisine Town is located in Ande Street, Pidu District, Chengdu, covering an area of approximately 10,847 acres. Centered around the "Sichuan Cuisine Culture" and "Modern Urban and Rural Integration" IPs, it has a comprehensive plan consisting of two axes, six cores, and six zones. Within the town, there are various attractions, such as the World Flavor Museum, which collects and sells 24 different Sichuan cuisine flavors, Shuxiang 183, a food experience center with the slogan "Give me a day, taste all of Sichuan," and the Food Adventure Park, where visitors can experience education and entertainment through technology and interactive adventure games. The town has been awarded honors such as the national "Intangible Cultural Heritage Tourism Town," "2021 Pidu District New Scene for Life and Consumption," "New Tourism · Trendy Chengdu," and other theme tourism destination titles. `,
    feature:
      "百馐百味百盘馔, 一步一景一卷画，传统中式文化兼具现代休闲功能的“产+居+旅”一体化美食文旅消费场景。",
    featureEn:
      " It is a beautiful, modern, and integrated food and tourism destination that combines traditional Chinese culture and modern leisure activities.",
    address: "安德街道",
    addressEn: "St.Ande",
    // phone: "梁印鑫13880681213",
    tel: "Liang 13880681213",
    longLatitude: "103.81,30.86",
    longLatitudeId: "B0G23HC3U4",
    left: 360,
    top: 320,
    width: 122,
    height: 87,
  },
  {
    id: "农科村（中国农家乐旅游发源地）",
    rName: "农科村（中国农家乐旅游发源地）",
    rNameEn: "Nongke Village(Origin of Chinese Agritainment Tourism)",
    cover: ["/sheet7/5-1-1.jpg"],
    description:
      "农科村位于成都市郫都区友爱镇，300余亩苗木花卉灿若繁星，桂花海棠争奇斗艳、银杏榕树比邻参天，桩头、盆景、造型等各具特色，各种名贵木本、草本花卉随处可见，被誉为“鲜花盛开的地方，没有围墙的公园”，2006年被国家旅游总局授予“中国农家乐旅游发源地”。",
    descriptionEn: `Nongke Village is located in Youai Town, Pidu District, Chengdu. With an area of over 300 acres, it is abundant with a variety of nurseries, flowers, and plants. The osmanthus and camellia trees compete in beauty, while the ginkgo and banyan trees reach towards the sky. There are various unique wooden and grassy flowers everywhere, making it known as "a park without walls where flowers are in full bloom". In 2006, it was awarded the title of "Origin of Chinese Agritainment Tourism" by the National Tourism Administration.`,
    feature: `国家AAAA级旅游景区，花木盆景生产、销售和农家旅游基地。`,
    featureEn:
      "Nongke village, a national AAAA-level tourist attraction, It serves as a production and sales base for flower and bonsai plants, as well as a rural tourism destination. You'ai Town.",
    address: "友爱镇",
    addressEn: "Town YouAi",
    // phone: "张磊13980448387",
    tel: "Zhang 13980448387",
    longLatitude: "103.80,30.82",
    longLatitudeId: "B0JUG58CR8",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
    left: 225,
    top: 475,
    width: 120,
    height: 160,
  },
  {
    id: "战旗村（全国乡村振兴示范村）",
    rName: "战旗村（全国乡村振兴示范村）",
    rNameEn: "Zhanqi Village(National Rural Revitalization Demonstration Village)",
    cover: ["/sheet7/6-1-1.jpg"],
    description:
      "战旗村位于成都市郫都区唐昌镇，2018年2月12日，习近平总书记来到战旗村视察，肯定战旗村“战旗飘飘，名副其实”，并对战旗村提出要在乡村振兴中继续“走在前列，起好示范”的发展要求。目前，战旗村景区建有战旗·初心馆、天府农耕文化博物馆、郫县豆瓣博物馆、乡村十八坊、乡村振兴培训学院等新业态新场景。同时，战旗村利用本地的工匠资源，还原旧时的作坊生产方式，打造农耕文化。",
    descriptionEn:
      `Zhanqi Village is located in Tangchang Town, Pidu District, Chengdu. On February 12, 2018, General Secretary Xi Jinping visited Zhanqi Village and praised it as a ""true flag-waving village"". He also demanded that Zhanqi Village continue to lead the way and set a good example in rural revitalization. Currently, the Zhanqi Village scenic area has new business formats and scenes, such as Zhanqi · Chuxin Hall, Tianfu Agricultural Culture Museum, Pixian Douban Museum, Rural Eighteen Square, and Rural Revitalization Training College. At the same time, Zhanqi Village utilizes local artisan resources to restore the old workshop production methods and create an agricultural culture.`,
    feature: "一个集旅游、 酒店、餐饮、休闲于一体的乡村特色消费场景。",
    featureEn:
      " It is a rural characteristic consumption scene that integrates tourism, hotels, catering, and leisure.",
    address: "唐昌镇",
    addressEn: "Town TangChang",
    // phone: "李光菊15928568207",
    tel: "LI 15928568207",
    longLatitude: "103.79,30.94",
    longLatitudeId: "B001C7TX3O",
    left: 609,
    top: 21,
    width: 84,
    height: 105,
  },
  {
    id: "青杠树村（中国十大最美乡村）",
    rName: "青杠树村（中国十大最美乡村）",
    rNameEn: "Qinggangshu Village(One of the Top 10 Most Beautiful Villages in China)",
    cover: ["/sheet7/7-1-1.jpg"],
    description:
      "三道堰青杠树景区三面环水，风景秀丽，集生态环境、田园风光、川西民居、林盘特色、小桥流水、鸟语花香于一体。春天园区内油菜花、结香花、桃花等春花纷纷展露娇颜，映衬着古色古香的川西民居建筑，别有一番意境，是踏青赏花的好去处。2015年，青杠树村荣获CCTV“2015中国十大最美乡村”称号。",
    descriptionEn:
      `The Qinggangshu Scenic Area in Sandaoyan is surrounded by water on three sides and has beautiful scenery. It combines ecological environment, pastoral scenery, western Sichuan folk houses, unique forests, small bridges and flowing water, and fragrant flowers and birds. In the spring, the park is filled with beautiful spring flowers such as rapeseed flowers, oriental paperbush flower, and peach blossoms, which complement the ancient western Sichuan folk houses and create a unique atmosphere. It is a good place to enjoy the scenery and flowers. In 2015, Qinggangshu Village was honored with the title of "One of the Top 10 Most Beautiful Villages in China" by CCTV.`,
    feature: "国家AAAA级旅游景区，农事体验、赏花品果、采摘游乐、农耕文化展现等有机联合的田园休闲旅游消费场景。",
    featureEn:
      "Qinggangshu village, a national AAAA-level tourist attraction, It is an organic combination of agricultural experience, flower and fruit appreciation, picking and amusement, and agricultural culture display, offering a rural leisure tourism consumption scene. Located in Sandaoyan Town.",
    address: "三道堰镇",
    addressEn: "Town SanDaoYan",
    // phone: "任定伟18080937647",
    tel: "Ren 18080937647",
    longLatitude: "103.95,30.85",
    longLatitudeId: "B001C8NL3Z",
    left: 1103,
    top: 370,
    width: 125,
    height: 105,
  },
  {
    id: "东林艺术村（最美川西林盘村）",
    rName: "东林艺术村（最美川西林盘村）",
    rNameEn: "Donglin Art Village(The Most Beautiful Linpan Village in Western Sichuan)",
    cover: ["/sheet7/8-1-1.jpg"],
    description:
      "东林艺术村位于成都市郫都区德源街道，以天府大地艺术季为契机，在川西林盘中植入多元消费场景，包括谷予下午茶、弥远咖啡等美食休闲空间和音乐街区、途远民宿、裸眼3D体验馆等特色消费场景，沉浸式体验天府大地美景美食。各个场景相互搭台，互为配套，彼此独立又相隔不远，形成抱团优势。“幸福美好生活”在这里触手可及。",
    descriptionEn: `Donglin Art Village is located in Deyuan Street, Pidu District, Chengdu. Taking the art festival as an opportunity, multiple consumption scenes have been implanted in the forest, including Tuyuan Homestay, Miyuan Coffee, and Naked Eye 3D, immersing in the natural beauty of Tianfu Land. The various scenes complement each other and are independent but not far apart, forming a group advantage. A happy and beautiful life is within reach here.`,
    feature: "以艺术的方式诠释成都人的生活方式和公园城市“乡村表达”的消费场景。",
    featureEn:
      'This is a consumption scenario that interprets the Chengdu lifestyle and the expression of "rural area" in the park city through art.',
    address: "德源街道",
    addressEn: "St. DeYuan",
    // phone: "陈星玥13551196561",
    tel: "Chen 13551196561",
    longLatitude: "103.85,30.77",
    longLatitudeId: "B0HGAL1NHG",
    left: 429,
    top: 690,
    width: 129,
    height: 72,
  },
  {
    id: "沙湾村（“新农人”景观农田）",
    rName: "沙湾村（“新农人”景观农田）",
    rNameEn: "Shawan Village ('New Farmers' Landscape Farmland')",
    cover: ["/sheet7/9-1-1.jpg"],
    feature: "集绿道运动、文创、休闲与蜀绣文化相结合的公园式消费场景。",
    featureEn:
      "A park-like consumer scene that combines greenway sports, cultural, creativity, leisure, and Sichuan embroidery culture.",
    longLatitude: "104.02,30.77",
    longLatitudeId: "B001C8VQ5P",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
  {
    id: "超级水工厂营地（大型实景研学教育基地）",
    rName: "超级水工厂营地（大型实景研学教育基地）",
    rNameEn: "Super Water Factory Campsite (Large-scale Experiential Learning Education Base)",
    cover: ["/sheet7/10-1-1.jpg"],
    feature:
      "国内首个立足于水工业与水文化以“生命安全教育、国防工科、优秀传统文化、自然生态”四大研学主题为背景而建设的大型实景研学教育基地。",
    featureEn:
      "The first large-scale real-life experiential learning and educational base in China, that is based on the water industry and water culture, with the four major research and learning themes of 'life safety education, defense engineering, outstanding traditional culture, and natural ecology'.",
    longLatitude: "103.91,30.86",
    longLatitudeId: "B0J6ADZLG2",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
  {
    id: "惠里特色商业街（苗苗鱼一条街）",
    rName: "惠里特色商业街（苗苗鱼一条街）",
    rNameEn: "Huili Special Commercial Street (Miaomiao Fish Street)",
    cover: ["/sheet7/11-1-1.jpg"],
    feature: "水的灵感与亭、台、楼、阁、塔、坊等建筑交相辉映，品水乡河鲜最佳之地。",
    featureEn:
      "Inspired by water, the pavilions, platforms, towers, halls, archways, and other architectural structures create a harmonious landscape, offering the best place to enjoy the fresh delicacies from the water town and rivers.",
    longLatitude: "103.92,30.87",
    longLatitudeId: "B0FFIVUON3",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
  {
    id: "菁蓉街（创客街区新标杆）",
    rName: "菁蓉街（创客街区新标杆）",
    rNameEn: "Jingrong Street(Innovator Street, a New Benchmark)",
    cover: ["/sheet7/12-1-1.jpg", "/sheet7/12-1-2.jpg", "/sheet7/12-1-3.jpg", "/sheet7/12-1-4.jpg", "/sheet7/12-1-5.jpg"],
    description: "菁蓉街地处国家首批双创示范基地菁蓉镇核心位置，街区总面积约1.2万㎡，总长度约800米，入驻商家三十七家，以“创客书咖”为引擎，升级构建复合型创客服务+生活配套+夜间消费+商务研学+旅游休憩功能，街区目前业态餐饮占比76%、休闲娱乐占比17%、生活配套占比7%，街区荣膺2020年成都“最美街道”第二名；2022年成都特色街区、十大最受青年喜爱的消费场景之一；2023年双拥特色街道。街区以创意设计IP吉祥物宇航员“菁菁”“蓉蓉”，具网红特质的泡泡树、彩虹部落等科技互动装置设置，艺术叠加重组集装箱形成的集展示、创意、漫时休闲等多功能创意屋，外摆、彩绘、夜景灯光系统交错形成的体验化场景设计;辅以科学的业态规划设置、专业化招商运营管理全力提升菁蓉街造血功能，打造服务电子信息产业功能区的生活高地，创造网红效应的成都创客街区新标杆!世界科幻大会期间，菁蓉街新增科幻氛围包装（灯光+装置+软式），在创客书咖新增科幻迷俱乐部及科幻沙龙功能，主街区布置科幻露营场地及科幻创意花车市集，设置主舞台现场乐队live秀，呼应科幻主题及打造社交消费场所。",
    descriptionEn: `Jingrong Street is located in the core of Jingrong Town, the first batch of demonstration base of business startups and innovation in China, with a total area of about 12,000m2 and a total length of about 800m, with 37 merchants settled-in, and it upgraded and built a compound maker service+living facilities+night consumption+business studies+tourism & leisure function with "Maker Book Cafe" as the engine. At present, the industry catering of the block accounts for 76%, leisure & entertainment account for 17%, and living facilities account for 7%. In 2020, the block won the second place in Chengdu's "Most Beautiful Streets"; in 2022, Chengdu's Characteristic Blocks and was among the Top Ten Most Popular Consumption Scenes for Young People; in 2023, Double Support Characteristic Streets.The block is creatively designed with IP mascots "Jingjing" and "Rongrong", scientific and technological interactive devices such as bubble tree and rainbow tribe with characteristics of online celebrities, a multi-functional idea house with display, creativity and leisure formed by artistic superposition and reorganization of containers, and an experiential scene design formed by staggered external furnishings, painting and night lighting systems; supplemented by scientific industrial state planning and professional investment operation management, Jingrong Street will fully improve its hematopoietic function, build a living highland serving the functional area of electronic information industry, and create a new benchmark of Chengdu Maker Street with online celebrity effect!During the World SF Convention, Jingrong Street added a sci-fi atmosphere package (lighting+devices+soft style), added a sci-fi fan club and a sci-fi salon function in the Maker Book Cafe, arranged a sci-fi camping site and a sci-fi creative float market in the main block, and set up a live band life show on the main stage to echo the sci-fi theme and to create a social consumption place.`,
    feature: "涵盖生活服务配套+夜间消费+商务研学+旅游休憩功能的创客街区新标杆。",
    featureEn:
      "A new benchmark for the maker district, encompassing a range of amenities including lifestyle services, nighttime entertainment, business research and learning, as well as tourism and leisure facilities.",
    longLatitude: "103.86,30.78",
    longLatitudeId: "B0FFH0INOT",
    left: 669,
    top: 750,
    width: 51,
    height: 48,
  },
  {
    id: "影视城光影街区（科创展示中心）",
    rName: "影视城光影街区（科创展示中心）",
    rNameEn: "Film and Television City Light and Shadow District(Science and Innovation Showcase Center)",
    cover: ["/sheet7/13-1-1.jpg"],
    feature:
      "集研发、产业、功能、服务为一体的影视传媒科创街区及科创展示中心，依托全息情景营造、VR/AR交互娱乐、4K/8K超高清沉浸式影院、裸眼3D球幕、传媒博物馆等，是集产业、服务、消费、观赏为一体的时尚社区。	",
    featureEn:
      "The film and media innovation district and innovation exhibition center integrate research and development, industry, functionality, and services. With the support of holographic scene creation, VR/AR interactive entertainment, 4K/8K ultra-high-definition immersive cinemas, naked-eye 3D domes, media museums, and more, it forms a fashionable community that combines industry, services, consumption, and appreciation.",
    longLatitude: "103.92,30.81",
    longLatitudeId: "B0FFJ91A6R",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
  {
    id: "望丛祠(西南地区最大帝王陵冢)",
    rName: "望丛祠(西南地区最大帝王陵冢)",
    rNameEn: "Wang Cong Temple(The largest imperial tomb mound in the southwestern region)",
    cover: ["/sheet7/14-1-1.jpg"],
    description:
      "望丛祠始建于南北朝，是纪念“天府前躯、蜀中先导”古蜀国望帝杜宇和丛帝开明的合祀祠，距今已有1500年历史，是西南地区最大的帝王陵冢。祠内水池环绕，碧波荡漾，殿宇陵墓之间，古树郁郁苍苍，亭榭错落有致。听鹃楼、赛歌台、湖心亭、博物馆、望丛二帝陵墓等主要景点闪耀其间。向园林深处寻觅，郫都区博物馆内说唱俑、石羊石马等著名文物都在这里整齐伫立。",
    descriptionEn:
      `Wang Cong Temple, founded during the Northern and Southern Dynasties period, is a combined shrine dedicated to the ancient rulers of Shu, Emperor Wang Di（Du Yu）and Emperor Cong Di, who are hailed as the "pioneers of Shu and the predecessors of the land of abundance." With a history spanning 1,500 years, it stands as the largest imperial tomb in the southwestern region of China. Within the temple, serene water pools gracefully meander, ancient trees flourish between the halls and tombs, and pavilions and kiosks are artfully arranged. Prominent attractions include the "Tingjuanlou Tower," "Saigetai Platform," "Huxin Pavilion," Museum, and the tombs of the two emperors, Wang Di and Cong Di. Venturing deeper into the gardens, you will discover the Pidu District Museum, where renowned artifacts like storytelling figurines and stone sculptures of sheep and horses are displayed in an orderly fashion.`,
    feature:
      "国家AAAA级旅游景区，始建于南北朝，是纪念“天府前躯、蜀中先导”古蜀国望帝杜宇和丛帝开明的合祀祠，距今已有1500年历史，是西南地区最大的帝王陵冢。",
    featureEn:
      "Wangcong Temple, a national AAAA-level tourist attraction, was founded during the Northern and Southern Dynasties period. It is a joint memorial temple dedicated to the ancient Shu Kingdom's Emperor Wang Diyu, known as the 'Pioneer of Sichuan,' and Emperor Cong Di, the 'Enlightener of the Masses.' With a history spanning 1500 years, it is the largest imperial mausoleum in the southwestern region.",
    longLatitude: "103.87,30.81",
    longLatitudeId: "B001C7U3QL",
    left: 607,
    top: 530,
    width: 53,
    height: 45,
  },
  {
    id: "三道堰景区（千年历史的川西古镇）",
    rName: "三道堰景区（千年历史的川西古镇）",
    rNameEn:
    "Sandaoyan Scenic Area(A thousand-year-old ancient town in western Sichuan)",
    cover: ["/sheet7/15-1-1.jpg"],
    description:
      "三道堰是一座具有千年历史的川西古镇，历史上曾是有名的水陆码头和商贸之地。古镇灵活运用了水源丰富多彩这一特性，依河建成错落有致的川西民居，形成独具特色的水乡风情小镇。沿柏条河两岸的吊脚楼、吟诗楼、品茶楼、书画楼、棋牌楼、饮食文化楼、观景楼等围绕在永定桥周围，遥相呼应。",
    descriptionEn:
      `Sandaoyan is an ancient town in western Sichuan with a history dating back a thousand years. In the past, it was a renowned river and land port as well as a hub for commerce. The town ingeniously utilizes its abundant water resources to construct a well-arranged traditional western Sichuan residences along the river, creating a distinctive water town with a unique ambiance. Along both banks of the Baitiao River, you will find various architectural gems such as stilted houses, poetry pavilions, teahouses, calligraphy and painting studios, chess pavilions, culinary culture halls, and scenic lookout towers, all arranged harmoniously around the Yongding Bridge, complementing each other in perfect harmony.`,
    feature:
      "国家AAAA级旅游景区，三道堰是一座有千年历史的川西古镇，灵活运用了水源丰富这一特性，依河建成错落有致的川西民居，形成独具特色的水乡风情小镇。",
    featureEn:
      "Sandaoyan is a national AAAA-level tourist attraction. It is a thousand-year-old ancient town in western Sichuan that skillfully utilizes its abundant water resources to construct charming traditional Sichuan-style homes along the river, creating a unique water town with distinctive local charm.",
    longLatitude: "103.91,30.86",
    longLatitudeId: "B0FFGK2XN7",
    left: 838,
    top: 250,
    width: 70,
    height: 70,
  },
  {
    id: "中国川菜体验园（感受郫县豆瓣三百年风雨历程）",
    rName: "中国川菜体验园（感受郫县豆瓣三百年风雨历程）",
    rNameEn:
      "Chinese Sichuan Cuisine Experience Park (Experience the 300-year History of Pixian Douban)",
    cover: ["/sheet7/16-1-1.jpg"],
    description:
      "园区依托鹃城、丹丹等国内知名川菜食品企业，打造了郫县豆瓣中国非遗文化体验园，游客通过逛车间、看工艺、品豆瓣、赏非遗，可以充分感受川菜之魂文化精髓；4000余平方米的川菜文化体验馆浓缩了川菜三千年的文化历史，再现了中国非物质文化遗产——郫县豆瓣三百年的风雨历程；2.3万平方米的川菜美食体验街，汇聚各类品牌餐饮、特色小吃，麻辣咸甜，百味汇聚，让味蕾在百转千回中流连忘返。",
    descriptionEn:
      `The park relies on well-known domestic Sichuan cuisine food enterprises such as Juancheng and Dandan to create the Pixian Douban Chinese Intangible Cultural Heritage Experience Park. Visitors can fully experience the cultural essence of Sichuan cuisine by touring the workshops, observing the craftsmanship, tasting doubanjiang (fermented chili bean paste), and appreciating intangible cultural heritage. The Sichuan Cuisine Cultural Experience Hall, spanning over 4,000 square meters, condenses the cultural history of Sichuan cuisine over three millennia and vividly showcases the 300-year journey of Pixian Douban, a Chinese intangible cultural heritage. Covering an area of 23,000 square meters, the Sichuan Cuisine Gourmet Experience Street brings together various brands of dining establishments and unique snacks, offering a plethora of flavors, ranging from spicy to salty, and providing an unforgettable culinary experience for taste buds amid countless gastronomic delights.`,
    feature:
      "国家AAA级旅游景区，游客通过逛车间、看工艺、品豆瓣、赏非遗，可以充分感受川菜之魂文化精髓。",
    featureEn:
      "Sichuan Cuisine Experience Hall, a national AAA-level tourist attraction, allows visitors to fully immerse themselves in the cultural essence and spirit of Sichuan cuisine through activities such as touring the workshops, observing the craftsmanship, tasting doubanjiang (Sichuan chili bean paste), and appreciating intangible cultural heritage.",
    longLatitude: "103.80,30.87",
    longLatitudeId: "B0FFFSYMOH",
    left: 360,
    top: 250,
    width: 122,
    height: 69,
  },
];
