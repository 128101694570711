export function getSession(key) {
  const str = sessionStorage.getItem(key);
  return str ? JSON.parse(str) : null;
}

export function setSession(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function removeSession(key) {
  sessionStorage.removeItem(key);
}

export function getLocal(key) {
  const str = localStorage.getItem(key);
  return str ? JSON.parse(str) : null;
}

export function setLocal(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}
